import { useContext } from "react";
import Context from "../context/Context";
import { ActiveUserFilter } from "../types/Users";

export default function useActiveUserFilters() {
  const { activeUserFilters } = useContext(Context);
  const isInActiveFiltersByProfessionalID = (professionnalID?: string) => {
    if (
      activeUserFilters.findIndex(
        (activeUserFilter: ActiveUserFilter) =>
          activeUserFilter.professional_id === professionnalID
      ) === -1
    )
      return false;
    return true;
  };

  const getFilters_id = () => {
    if (activeUserFilters.length > 0)
      return "id:".concat(
        activeUserFilters
          .map((activeUserFilter: ActiveUserFilter) => {
            return activeUserFilter.id;
          })
          .join(",")
      );
    else return "";
  };

  const getFilters_professional_id = (): string => {
    if (activeUserFilters.length > 0)
      return activeUserFilters
        .map((activeUserFilter: ActiveUserFilter) => {
          return activeUserFilter.professional_id;
        })
        .join(",");
    else return "";
  };

  return {
    getFilters_professional_id,
    getFilters_id,
    isInActiveFiltersByProfessionalID,
  };
}
