import { Breadcrumb, Button } from "antd";
import React, { useContext, useState } from "react";
import Blocks from "./sections/Blocks";
import InputText from "./sections/InputText";
import Output from "./sections/Output";
import Variables from "./sections/Variables";
import { BlockType, VariableType } from "../../../types/AITypes";
import AIContext from "../../../context/AIContext";
import AILabHeader from "./sections/components/AILabHeader";
import useJSONForAI from "../../../hooks/useJSONForAI";
import Commands from "./sections/components/Commands";

export default function LLMTesting() {
  const [title, setTitle] = useState<string>("");
  const [inputText, setInputText] = useState<string>("");
  const [blocks, setBlocks] = useState<BlockType[]>([]);
  const [variables, setVariables] = useState<VariableType[]>([]);
  const [outputText, setOutputText] = useState<string>("");

  const [frequencyPenality, setFrequencyPenality] = useState<number>(0);
  const [presencePenality, setPresencePenality] = useState<number>(0);
  const [temperature, setTemperature] = useState<number>(0);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);

    const file: File = e.dataTransfer.files[0];
    if (file.type === "application/json") {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>): void => {
        const jsonData: {
          title: string;
          variables: VariableType[];
          blocks: BlockType[];
          inputText: string;
          outputText: string;
          temperature: number;
          frequencyPenality: number;
          presencePenality: number;
        } = JSON.parse(event.target!.result as string);
        setTitle(jsonData.title);
        setVariables(jsonData.variables);
        setBlocks(jsonData.blocks);
        setInputText(jsonData.inputText);
        setOutputText(jsonData.outputText);
        setPresencePenality(jsonData.presencePenality);
        setFrequencyPenality(jsonData.frequencyPenality);
        setTemperature(jsonData.temperature);
      };
      reader.readAsText(file);
    } else {
      console.error("Unsupported file type");
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const initialState = {
    title,
    updateTitle: setTitle,

    inputText,
    updateInputText: setInputText,

    blocks,
    updateBlocks: setBlocks,

    variables,
    updateVariables: setVariables,

    outputText,
    updateOutputText: setOutputText,

    frequencyPenality: frequencyPenality,
    updateFrequencyPenality: setFrequencyPenality,

    presencePenality: presencePenality,
    updatePresencePenality: setPresencePenality,

    temperature: temperature,
    updateTemperature: setTemperature,
  };
  return (
    <AIContext.Provider value={initialState}>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          // padding: "4px",
          flexDirection: "column",
          gap: "16px",
          boxSizing: "border-box",
          // border: "2px solid red",
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Breadcrumb
          items={[
            {
              title: "AI Lab",
            },
          ]}
        />
        {isDragging ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              textAlign: "center",
              border: "6px dashed rgba(255,255,255,0.3)",
              borderRadius: "32px",
            }}
          >
            <div style={{ fontSize: "48px", color: "rgba(255,255,255,0.3)" }}>
              Charger un JSON
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <AILabHeader />
            <div
              style={{
                display: "flex",
                flex: "1 1 1",
                height: "100%",
                width: "100%",
                flexDirection: "row",
                gap: "16px",
                boxSizing: "border-box",
              }}
            >
              {/* Trois colonnes */}
              {/* Colonne d'input de texte */}
              {/* <InputText /> */}
              <Blocks />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  gap: "8px",
                  boxSizing: "border-box",
                }}
              >
                <Variables />
                <Output />
                <Commands />
              </div>
            </div>
          </div>
        )}
      </div>
    </AIContext.Provider>
  );
}
