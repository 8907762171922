import { createContext } from "react";
import { BlockType, VariableType } from "../types/AITypes";

export default createContext({
  title: "",
  updateTitle: (arg: string) => {},

  inputText: "",
  updateInputText: (arg: string) => {},

  // blocks: [
  //   {
  //     id: "string",
  //     title: "string",
  //     input: "inputText",
  //     prompt: "string",
  //     outputOperation: "add",
  //     outputVariable: "outputText",
  //   }] as BlockType[],
  blocks: [] as BlockType[],
  updateBlocks: (arg: BlockType[]) => {},

  variables: [] as VariableType[],
  updateVariables: (arg: VariableType[]) => {},

  outputText: "",
  updateOutputText: (arg: string) => {},

  presencePenality: 0,
  updatePresencePenality: (arg: number) => {},

  frequencyPenality: 0,
  updateFrequencyPenality: (arg: number) => {},

  temperature: 0,
  updateTemperature: (arg: number) => {},
});
