import React from "react";
import { Pie } from "react-chartjs-2";
import { Subscription } from "@thiana/api-thiana-client";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

ChartJS.register(ArcElement, Tooltip, Legend, Title);

interface Props {
  subscriptions: Subscription[];
}

function SubscriptionPieChart(props: Props) {
  const { subscriptions } = props;

  const statusCounts = subscriptions.reduce(
    (acc: any, subscription) => {
      const trialUntilDate = dayjs(
        subscription.trial_until,
        "DD/MM/YYYY HH:mm:ss"
      );
      const isExpired =
        subscription.status === "trialing" && trialUntilDate.isBefore(dayjs());

      if (isExpired) {
        acc.expired += 1;
      } else {
        if (subscription.status === "active") {
          if (subscription.payment_level === "assistant integral") {
            acc.essential += 1;
          } else {
            acc.integral += 1;
          }
        } else acc[subscription.status] = (acc[subscription.status] || 0) + 1;
      }

      return acc;
    },
    {
      essential: 0,
      integral: 0,
      trialing: 0,
      canceled: 0,
      expired: 0,
    }
  );

  const data = {
    labels: [
      "Offres Essentielles",
      "Offres Intégrales",
      "Période d'essai",
      "Aucun abonnement",
      "Période d'essai expirée",
    ],
    datasets: [
      {
        data: [
          statusCounts.essential,
          statusCounts.integral,
          statusCounts.trialing,
          statusCounts.canceled,
          statusCounts.expired,
        ],
        backgroundColor: ["#36A2EB", "#023658", "#FFCE56", "red", "#ff3762"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text:
          "Répartition des abonnements utilisateurs (total : " +
          subscriptions.length +
          ")",
      },
    },
  };

  return (
    <div style={{ width: 300, height: 300, margin: "0 auto" }}>
      <Pie data={data} options={options} />
    </div>
  );
}

export { SubscriptionPieChart };
