import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Switch } from "antd";
import { io } from "socket.io-client";

const URL_SBM_PREPROD = process.env.REACT_APP_URL_BACKIA_PREPROD as string;
const URL_SBM_DEV = process.env.REACT_APP_URL_BACKIA_DEV as string;

declare global {
  interface Window {
    webkitAudioContext: any;
  }
}

export default function ASRTesting() {
  const [audioChunks, setAudioChunks] = useState<string[]>([]);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [audioURL, setAudioURL] = useState<string>("");
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [fullyValidatedText, setFullyValidatedText] = useState<string>("");
  const [validatedText, setValidatedText] = useState<string>("");
  const [inValidationText, setinValidationText] = useState<string>("");
  const [isPreprodChecked, setIsPreprodChecked] = useState<boolean>(false);

  const SOCKET_ASR = useRef<any>();

  // Function to encode WAV data
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const audioBuffer = reader.result as ArrayBuffer;
        const base64str = arrayBufferToBase64(audioBuffer);
        const audioDataURL = `data:audio/wav;base64,${base64str}`;
        setAudioURL(audioDataURL);

        const chunkSize = 1600 * 2;
        const totalChunks = Math.ceil(audioBuffer.byteLength / chunkSize);
        const chunks: string[] = [];
        for (let i = 0; i < totalChunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(start + chunkSize, audioBuffer.byteLength);
          const chunk = audioBuffer.slice(start, end);
          const base64str = arrayBufferToBase64(chunk);
          const audioChunkToSend = `data:audio/wav;base64,${base64str}`;
          chunks.push(audioChunkToSend);
        }
        setAudioChunks(chunks);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const sendChunks = async () => {
    const env = localStorage.getItem("env");
    const token = localStorage.getItem("accessJWT-" + env);
    try {
      // On connecte la websocket entre le front et le back ia
      SOCKET_ASR.current = io(
        isPreprodChecked ? URL_SBM_PREPROD : URL_SBM_DEV,
        {
          auth: {
            token: token,
          },
        }
      );
      // Connect error
      SOCKET_ASR.current.on("connect_error", (error: any) => {
        console.error(error);
      });

      // on connect websocket
      SOCKET_ASR.current.on("connect", () => {
        console.log("TRANSCRIPTION : Connected to websocket.");
        SOCKET_ASR.current?.emit("informations", {
          mode: "dictation",
          type: "0001",
          focusKey: "0",
          blockKey: "0",
        });
      });

      SOCKET_ASR.current.on("live_feedback", (response: any) => {
        console.log(response);
        setFullyValidatedText(response.text.slice(0, response.fully_validated));
        setValidatedText(
          response.text.slice(response.fully_validated, response.validated)
        );
        setinValidationText(
          response.text.slice(response.validated, response.text.length)
        );
        setFeedbackText(response.text);
      });

      for (let i = 0; 1 < audioChunks.length; i++) {
        let chunkToSend = audioChunks[i];
        // console.log(chunkToSend)
        SOCKET_ASR.current.emit("command", chunkToSend);
        await delay(70);
      }

      SOCKET_ASR.current.disconnect();
    } catch (error) {
      // Erreur websocket
      console.error(error);
      SOCKET_ASR.current.disconnect();
    }
  };

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const stopASRTest = () => {
    SOCKET_ASR.current.disconnect();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        gap: 16,
        padding: 32,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <Input
          type="file"
          accept=".wav"
          onChange={(e) => handleFileChange(e)}
        />
        <Switch onChange={(value) => setIsPreprodChecked(value)} />
        <p>{isPreprodChecked ? "Preprod" : "Dev"}</p>
      </div>
      {audioURL && (
        <audio controls ref={audioPlayerRef}>
          <source src={audioURL} type="audio/wav" />
        </audio>
      )}
      <div
        style={{
          display: "flex",
          gap: "16px",
          padding: 8,
        }}
      >
        <Button onClick={sendChunks}>Send Chunks</Button>
        <Button type="primary" danger onClick={stopASRTest}>
          Couper
        </Button>
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "16px",
          backgroundColor: "#141414",
          border: "1px solid #545454",
          padding: 16,
          whiteSpace: "pre-wrap",
          overflow: "scroll",
        }}
      >
        <span>{fullyValidatedText}</span>
        <span style={{ color: "rgba(255,170,120,0.8)" }}>{validatedText}</span>
        <span style={{ color: "rgba(255,120,120,0.6)" }}>
          {inValidationText}
        </span>
      </div>
    </div>
  );
}
