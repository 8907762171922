import { Button, Drawer, Input, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import colors from "../../../theme/thiana";
import { UserDataSource } from "../Users";
import Context from "../../../context/Context";
import { UserOutput } from "@thiana/api-thiana-client";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  usersDataSource: UserDataSource[];
  setUsersDataSource: React.Dispatch<React.SetStateAction<UserDataSource[]>>;
  selectedUser: UserDataSource | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<UserDataSource | null>>;
  updateUserList: (updatedUser: UserDataSource) => void;
};

export default function DrawerListUsers(props: Props) {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: props.selectedUser?.first_name,
    lastName: props.selectedUser?.last_name,
    email: props.selectedUser?.email,
    birthDate: props.selectedUser?.birth_date,
  });
  const [birthDateError, setBirthDateError] = useState<string | null>(null);
  const { apiThianaClient } = useContext(Context);

  const onClose = () => {
    props.setOpen(false);
    props.setSelectedUser(null);
    setHasChanges(false);
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      birthDate: "",
    });
  };

  const openConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const handleConfirmModalCancel = () => {
    setConfirmModalVisible(false);
  };

  const handleConfirmModalOk = async () => {
    let id = props.selectedUser?.id;
    if (id) {
      let response = await apiThianaClient.apiUsers.userPatchRaw({
        id: id,
        userPartialInput: {
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          birth_date: formValues.birthDate,
        },
      });

      if (response.raw.status === 200) {
        let userOutput: UserOutput = await response.raw.json();
        setHasChanges(false);
        setConfirmModalVisible(false);

        // On met à jour l'éléments du tableau avec la reponse du PATCH
        if (userOutput.data[0].professional_id) {
          let index = props.usersDataSource.findIndex(
            (user) => user.id === userOutput.data[0].id
          );
          let tmp = [...props.usersDataSource];
          tmp[index] = {
            ...userOutput.data[0],
            key: userOutput.data[index].id,
          };
          props.setUsersDataSource(tmp);
        }
      }
    }
    setHasChanges(false);
    setConfirmModalVisible(false);
    props.setOpen(false);
  };

  const handleInputChange = async (fieldName: string, value: string) => {
    const updatedFormValues = {
      ...formValues,
      [fieldName]: value,
    };
    setFormValues(updatedFormValues);

    if (fieldName === "birthDate") {
      const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Regex pour le format dd/mm/yyyy
      if (!regex.test(value)) {
        setBirthDateError(
          "Le format de la date de naissance est invalide (dd/mm/yyyy)"
        );
        return;
      } else {
        setBirthDateError(null);
      }
    }
    setHasChanges(true);
  };

  useEffect(() => {
    setFormValues({
      firstName: props.selectedUser?.first_name || "",
      lastName: props.selectedUser?.last_name || "",
      email: props.selectedUser?.email || "",
      birthDate: props.selectedUser?.birth_date || "",
    });
  }, [props.selectedUser]);

  const checkEmail = () => {
    if (isEmailValid) return null;
    else
      return (
        <div style={{ color: colors.palette.danger[600], fontSize: 10 }}>
          Format invalide
        </div>
      );
  };

  return (
    <div>
      <Drawer
        style={{ background: "#1c1c1c" }}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: colors.palette.grey[100] }}>
              User modifications
            </span>
            {/* <Button type="primary" danger onClick={onClose}>
              Supprimer
            </Button> */}
          </div>
        }
        placement="right"
        onClose={onClose}
        open={props.open}
        width={"50%"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ marginBottom: 16 }}>
              <div style={{ display: "flex", gap: 16, marginBottom: 15 }}>
                <div style={{ width: "100%" }}>
                  <label>Prénom</label>
                  <Input
                    placeholder="Prénom"
                    value={formValues.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                  />
                </div>
                {/* <div style={{ width: 96 }}>
                  <label>Sexe</label>
                  <Select
                    showSearch
                    placeholder="Choisir"
                    // value={props.selectedUser.}
                    style={{ width: "100%" }}
                    // onChange={handleChangeSex}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      { value: "M", label: "Homme" },
                      { value: "F", label: "Femme" },
                    ]}
                  />
                </div> */}
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 15 }}>
                <div style={{ width: "100%" }}>
                  <label>Nom</label>
                  <Input
                    placeholder="Nom"
                    value={formValues.lastName}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            {/* Contact */}
            <div style={{ marginBottom: 16 }}>
              <h3>Contact</h3>
              <div style={{ display: "flex", gap: 16, marginBottom: 15 }}>
                <div style={{ width: "100%" }}>
                  <label>Email</label>
                  <Input
                    placeholder="exemple@gmail.com"
                    value={
                      props.selectedUser?.email ? props.selectedUser?.email : ""
                    }
                  />
                  {checkEmail()}
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 15 }}>
                <div style={{ width: "100%" }}>
                  <label>Date de naissance</label>
                  <Input
                    placeholder="date de naissance"
                    value={formValues.birthDate}
                    onChange={(e) =>
                      handleInputChange("birthDate", e.target.value)
                    }
                  />
                  {birthDateError && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {birthDateError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button
              style={{ background: "yellow", color: "black" }}
              onClick={openConfirmModal}
              disabled={!hasChanges}
            >
              Modifier
            </Button>
          </div>
        </div>
      </Drawer>
      <Modal
        title="Confirmer la modification"
        open={confirmModalVisible}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
      >
        <p>Êtes-vous sûr de vouloir modifier cet utilisateur ?</p>
      </Modal>
    </div>
  );
}
