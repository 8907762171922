import { jwtDecode } from "jwt-decode";
import { AccessToken } from "../types/Token";

const hasValidToken = (env: string) => {
  let encodedToken: any = localStorage.getItem("accessJWT-" + env);
  if (encodedToken === null) return false;
  let token: AccessToken = jwtDecode(encodedToken);
  if (Date.now() >= token.exp * 1000) {
    localStorage.removeItem("accessJWT-" + env);
    localStorage.removeItem("refreshJWT-" + env);
    return false;
  }
  return true;
};

export { hasValidToken };
