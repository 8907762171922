import { notification } from "antd";
import { useContext } from "react";
import Context from "../context/Context";

export default function useLogin() {
  const { apiThianaClient } = useContext(Context);
  const login = async (email: string, password: string) => {
    let response = await apiThianaClient.apiAuth.authUserLoginRaw({
      loginInput: { email, password },
    });
    if (response.raw.status === 200) {
      let response2 = await apiThianaClient.apiAuth.authScopeRaw({
        scopeInput: { scope: 4 },
      });
      if (response2.raw.status === 200) {
        notification.success({
          message: "Connexion",
          description: "Vous êtes connecté(e).",
        });
        return true;
      }
      return false;
    }
  };

  return { login };
}
