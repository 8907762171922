import React, { useContext } from "react";
import AIContext from "../../../../../context/AIContext";
import { Input, InputNumber } from "antd";

export default function AILabHeader() {
  const {
    title,
    updateTitle,
    frequencyPenality,
    updateFrequencyPenality,
    presencePenality,
    updatePresencePenality,
    temperature,
    updateTemperature,
  } = useContext(AIContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 16,
          alignItems: "center",
        }}
      >
        <Input
          value={title}
          onChange={(e: any) => updateTitle(e.target.value)}
          placeholder="Titre"
          style={{ height: "30px" }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Frequency penality :</p>
          <InputNumber
            value={frequencyPenality}
            step={0.1}
            onChange={(value) => updateFrequencyPenality(value as number)}
            type="number"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Presence penality :</p>
          <InputNumber
            value={presencePenality}
            step={0.1}
            onChange={(value) => updatePresencePenality(value as number)}
            type="number"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Temperature :</p>
          <InputNumber
            value={temperature}
            step={0.1}
            onChange={(value) => updateTemperature(value as number)}
            type="number"
          />
        </div>
      </div>
    </>
  );
}
