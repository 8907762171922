import { ActiveUserFilter } from "../types/Users";

const showS = (length: number) => {
  if (length > 1) return "s";
  return "";
};
const getFormattedGlobal = (length: number) => {
  if (length > 1) return "globaux";
  return "global";
};

const getFormattedActiveFilters = (activeUserFilters: ActiveUserFilter[]) => {
  return (
    activeUserFilters.length.toString() +
    " " +
    " filtre" +
    showS(activeUserFilters.length) +
    " " +
    getFormattedGlobal(activeUserFilters.length) +
    " " +
    "actif" +
    showS(activeUserFilters.length)
  );
};

export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

export { showS, getFormattedGlobal, getFormattedActiveFilters };
