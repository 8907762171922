export const convertDateIntoTimestamp = (dateString: string) => {
  // Diviser la chaîne en parties pour obtenir les éléments de la date
  let dateParts = dateString.split(" ");
  let date = dateParts[0].split("/");
  let time = dateParts[1].split(":");
  // Créer un objet Date
  let jsDate = new Date(
    parseInt(date[2]),
    parseInt(date[1]) - 1,
    parseInt(date[0]),
    parseInt(time[0]),
    parseInt(time[1]),
    parseInt(time[2])
  );

  // Obtenir le timestamp en seconde
  return jsDate.getTime() / 1000;
};
