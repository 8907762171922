import React, { useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Block } from "typescript";
import AIContext from "../context/AIContext";
import { BlockType, PromptItem, VariableType } from "../types/AITypes";

const URL_SBM = process.env.REACT_APP_URL_BACKIA_PREPROD as string;
// const URL_SBM = process.env.REACT_APP_URL_BACKIA_DEV as string;

export default function useLLM() {
  const socketRef = useRef<any>();
  const {
    inputText,
    blocks,
    variables,
    outputText,
    updateVariables,
    updateOutputText,
    updateBlocks,
    temperature,
    frequencyPenality,
    presencePenality,
  } = useContext(AIContext);

  const runLLM = async (
    inputData: PromptItem[],
    maxNewTokens: number = 1024
  ): Promise<string> => {
    /* Connects to websockets to the back IA and send
      text data to run the llm.
      Returns the result when the operation is complete.
      Note : operation can be quite long -> async function     
      */
    const env = localStorage.getItem("env");
    const token = localStorage.getItem("accessJWT-" + env);
    console.log(token);
    try {
      return new Promise<string>(async (resolve, reject) => {
        socketRef.current = io(URL_SBM, {
          auth: {
            token: token,
          },
        });
        // on connect
        socketRef.current.on("connect", () => {
          console.log("Connected to websocket.");
          socketRef.current.emit("test", {
            // data: {
            data: inputData,
            presence_penalty: presencePenality,
            frequency_penalty: frequencyPenality,
            temperature: temperature,
            max_tokens: maxNewTokens,
            // }
          });
        });
        //Listen
        socketRef.current.on("summary_callback", async (response: any) => {
          if (response.finished) {
            updateOutputText(response.summary);
            socketRef.current.disconnect();
            resolve(response.summary);
          } else {
            updateOutputText(response.summary);
          }
        });
      });
    } catch (error) {
      // Erreur websocket
      console.error(error);
      throw new Error("An error occurred while running LLM.");
    }
  };

  const launchBlock = async (block: BlockType) => {
    let blockCopy = JSON.parse(JSON.stringify(block));

    let prompts: PromptItem[] = JSON.parse(JSON.stringify(blockCopy.prompt));
    //on remplace dans chacune des prompts les valeurs entre crochet par le contenu attendu
    prompts.forEach((obj) => {
      let variableNames = variables.map((variable) => variable.name);
      console.log(variables);
      let regexPattern = variableNames.map((name) => `\\{${name}\\}`).join("|");
      let regex = new RegExp(regexPattern, "g");

      obj.content = obj.content?.replace(regex, (match) => {
        const variable = variables.find((v) => `{${v.name}}` === match);
        return variable ? variable.value : match;
      });
    });

    let dataToSend: PromptItem[] = [];
    //On lance le LLM pour chaque type user qui n'a pas de contenu. On coupe la loop en fonction des conditions
    let breakLoop = false;
    let index = 0;
    for (const prompt of prompts) {
      let updatedBlocks = [...blocks];
      if (prompt.role !== "condition") {
        dataToSend.push(prompt);
        let summary: string = "";
        switch (dataToSend[dataToSend.length - 1].role) {
          case "user":
            summary = await runLLM(dataToSend, prompt.maxNewTokens as number);
            const new_prompt: PromptItem = {
              role: "assistant",
              content: summary,
              maxNewTokens: null,
            };
            let blockCopy = JSON.parse(JSON.stringify(block));
            const updatedPrompts = [...blockCopy.prompt];
            // console.log("prompts.indexOf(prompt)", prompts.indexOf(prompt));
            // console.log("updatedPrompts", updatedPrompts);
            // console.log("prompt", prompt);
            updatedPrompts.splice(index + 1, 0, new_prompt);

            console.log("updatedBlocks", updatedBlocks);
            const updatedBlock = updatedBlocks[blocks.indexOf(block)];
            updatedBlock.prompt = updatedPrompts;
            updatedBlocks.splice(updatedBlocks.indexOf(block), 1, updatedBlock); //...then here I do some modifications...
            updateBlocks(updatedBlocks); //...and finally I update it.
            dataToSend.push(new_prompt);
            index = index + 2;
            break;
          case "assistant":
            index = index + 1;
            break;
        }
      } else {
        let lastPrompt: PromptItem = dataToSend[
          dataToSend.length - 1
        ] as PromptItem;
        if (
          lastPrompt.content.includes(
            prompt.conditionData?.conditionString as string
          )
        ) {
          if (!prompt.conditionData?.continue as boolean) {
            breakLoop = true;
          }
        }
        index = index + 1;
      }
      if (breakLoop) break;
    }
  };

  const launchPipeline = async () => {
    for (const block of blocks) {
      launchBlock(block);
    }
  };
  const launchBlockByID = async (id: string) => {
    let block = blocks.find((b) => b.id === id);
    launchBlock(block as BlockType);
  };

  const abortPipeline = () => {
    socketRef.current.disconnect();
  };

  return { launchPipeline, abortPipeline, launchBlockByID };
}

// let processOutput = (summary: string, block: BlockType) => {
//   switch (block.outputVariable) {
//     case "outputText":
//       updateOutputText(summary);
//       break;
//     case "continue":
//       const new_prompt: PromptItem = {
//         role: "assistant",
//         content: summary,
//       };
//       let blockCopy = { ...block };
//       const updatedPrompts = [...blockCopy.prompt];
//       updatedPrompts.push(new_prompt);

//       const updatedBlocks = [...blocks];
//       const updatedBlock = block;
//       updatedBlock.prompt = updatedPrompts;
//       updatedBlocks.splice(blocks.indexOf(block), 1, updatedBlock);
//       updateBlocks(updatedBlocks);
//       break;
//     default:
//       let variableOutput: VariableType = variables.find(
//         (variable: VariableType) => variable.id === block.outputVariable
//       ) as VariableType;
//       console.log("variableOutput", variableOutput);
//       let updatedVariableOutput: VariableType = variableOutput;
//       switch (block.outputOperation) {
//         case "add":
//           updatedVariableOutput.value =
//             updatedVariableOutput.value + "\n\n" + summary;
//           break;
//         case "replace":
//           updatedVariableOutput.value = summary;
//           break;
//         default:
//           break;
//       }
//       console.log("go");
//       let updatedVariables = [...variables];
//       updatedVariables.splice(
//         updatedVariables.indexOf(variableOutput),
//         1,
//         updatedVariableOutput
//       );
//       updateVariables(updatedVariables);
//       break;
//   }
// };
