import { Button, Input } from "antd";
import { useContext, useId, useState } from "react";
import AIContext from "../../../../context/AIContext";
import { VariableType } from "../../../../types/AITypes";
import Variable from "./components/Variable";

export default function Variables() {
  const { variables, updateVariables } = useContext(AIContext);

  const addVariable = () => {
    const newVariable: VariableType = {
      id: Math.random().toString(16).slice(2),
      name: "",
      value: "",
    };
    const updatedVariables = [...variables];
    updatedVariables.push(newVariable);
    updateVariables(updatedVariables);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "60vh",
          borderRadius: "8px",
          padding: "8px",
          gap: "4px",
          // backgroundColor: "#141414",
          border: "1px solid #545454",
          boxSizing: "border-box",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Variables</p>
          <Button onClick={addVariable}>Add variable</Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
            gap: "4px",
            width: "100%",
            // border: "1px solid red",
            boxSizing: "border-box",
          }}
        >
          {variables.map((variable: VariableType) => {
            return (
              <Variable
                id={variable.id}
                name={variable.name}
                value={variable.value}
              ></Variable>
            );
          })}
        </div>
      </div>
    </>
  );
}
