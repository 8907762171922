import React, { useEffect, useState } from "react";

declare global {
  interface Window {
    Synapse: any;
  }
}

export default function TestSynapse() {
  window.Synapse.setLang("fr-FR");
  window.Synapse.initContext("1234567890abcdef");

  const [synapseLoaded, setSynapseLoaded] = useState(false);
  const [entities, setEntities] = useState<any[]>([]);
  const [allergies, setAllergies] = useState<any[]>([]);

  const [data, setData] = useState<any>({
    entities: [],
    profile: {
      grounds: {
        kidneyFailure: window.Synapse.ProfileType.KidneyProfile.LIGHT,
        hepaticFailure: window.Synapse.ProfileType.HepaticProfile.MODERATE,
        cardiacFailure: window.Synapse.ProfileType.CardiacProfile.HEAVY,
        allergies: [],
        comorbidities: [],
      },
      age: 23,
      height: 185,
      heightUnit: "CM",
      weight: 75,
      weightUnit: "KG",
      creatinine: {
        value: 83,
        unit: window.Synapse.ProfileType.CreatinineUnit.UMOL_L,
      },
      medicalHistory: [""],
      sex: window.Synapse.ProfileType.Sex.FEMALE,
      pregnant: true,
      pregnancyStartDate: new Date("2021-09-12"), // must be within the last nine months
      nursing: false,
      birthDate: new Date("2022-09-12"),
      lmp: new Date("2022-01-11"),
    },
  });

  const JWT =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJ0aGlhbmEtdGVzdCIsImV4cCI6MTcyMjMyNzI3M30.ObtlNBWUTBqQ2QB3hsSq-SjOd-3PxAc-kW7kxEwvQwo";

  //Déclaration des widgets
  const autocompleteWidget =
    new window.Synapse.MedicalResourceAutocompleteWidget(JWT);
  const dataAutocomplete = {
    entityTypes: [
      window.Synapse.MedicalResourceEntityType.BRANDED_DRUGS,
      window.Synapse.MedicalResourceEntityType.PHARMACEUTICAL_INGREDIENTS,
      window.Synapse.MedicalResourceEntityType.CLINICAL_DRUGS,
    ],
  };

  const prescriptionSummaryWidget =
    new window.Synapse.PrescriptionSummaryWidget(JWT);

  const allergyAutocomplete = new window.Synapse.AllergyAutocompleteWidget(JWT);

  //Widget Monography
  const drug = {
    cis: "64338986",
    drug_id: "64338986",
    shortname: "AMOXICILLINE",
    brandname: "AMOXICILLINE ALMUS",
    label_dose: "500 mg",
    label_form: "gélule",
    clinical_drug_label: "Amoxicilline 500 mg gélule",
    form: ["gélule"],
    is_originator: false,
    is_generic: true,
    is_atu: false,
    amm_date: "14/03/1997",
    amm_status: "Autorisation active",
    appearance_frequency: "7.943182e-05",
    administration_route: "orale",
    administration_routes: ["orale"],
    ingredients: [
      {
        atc: ['{"code": "J01CA04", "label": "AMOXICILLINE"}'],
        alt_labels: ["amoxicillin trihydrate", "amoxicilline trihydratée"],
        formOf: [
          {
            atc: [],
            alt_labels: ["amoxicilline", "amoxicilline anhydre", "amoxicillin"],
            formOf: [],
            alt_doses: [],
            label: "amoxicilline",
            uri: "http://frnorm.graph.synapse-medicine.com#PharmaceuticalIngredient/27ca510d1b49ac3dc05fa7342008af76490df196",
            type: "pharmaceutical-ingredient",
          },
        ],
        dose: "-1 UNK",
        alt_doses: ["-1 UNK"],
        label: "amoxicilline trihydratée",
        uri: "http://frnorm.graph.synapse-medicine.com#PharmaceuticalIngredient/7258808f8c58aa19f625ecad4cca70415909b1fc",
        type: "pharmaceutical-ingredient",
      },
    ],
    moieties: [
      {
        atc: ['{"code": "J01CA04", "label": "AMOXICILLINE"}'],
        alt_labels: ["amoxicilline", "amoxicilline anhydre", "amoxicillin"],
        formOf: [],
        dose: "500 mg",
        alt_doses: ["500 mg"],
        label: "amoxicilline",
        uri: "http://frnorm.graph.synapse-medicine.com#PharmaceuticalIngredient/27ca510d1b49ac3dc05fa7342008af76490df196",
        type: "pharmaceutical-ingredient",
      },
    ],
    moieties_label: "amoxicilline",
    moieties_alt_labels: [
      "amoxicilline",
      "amoxicilline anhydre",
      "amoxicillin",
    ],
    num_ingredients: 1,
    packagings: [
      {
        label:
          "AMOXICILLINE ALMUS 500 mg, gélule, plaquette(s) thermoformée(s) PVC PVDC aluminium de 12 gélule(s)",
        uri: "http://bdpm.graph.synapse-medicine.com#PackagedBrandedDrug/3400937054393",
        cip13: "3400937054393",
        reimbursement_rate: "0.65",
        conditioning:
          "plaquette(s) thermoformée(s) PVC PVDC aluminium de 12 gélule(s)",
        is_exceptional_drug: false,
        package_nb_units: "12",
      },
    ],
    prescription_conditions: [
      {
        label: "liste I",
        category: "PRESCRIPTION MEDICALE",
        subcategory: "Médicaments soumis à prescription médicale, sur liste I",
      },
    ],
    atc: [
      '{"code": "J01CA04", "label": "AMOXICILLINE"}',
      '{"code": "J01CA", "label": "PENICILLINES A LARGE SPECTRE"}',
    ],
    rcp_available: true,
    package_prices: {
      minPrice: 1.54,
      maxPrice: 1.54,
      currency: "EUR",
    },
    package_unit_prices: {
      minPrice: 0.128,
      maxPrice: 0.128,
      currency: "EUR",
    },
    reimbursement_rate: 0.65,
    laboratory: "BIOGARAN",
    fr_equivalent_drugs: [],
    label: "AMOXICILLINE ALMUS 500 mg, gélule",
    uri: "http://frnorm.graph.synapse-medicine.com#BrandedDrug/64338986",
    type: "branded-drug",
    is_reimbursed: true,
    is_biosimilar_ref: false,
    is_exceptional_drug: false,
    alds: [
      {
        ald_code: "ALD.30.02",
        ald_label: "Mélanome cutané",
      },
      {
        ald_code: "ALD.30.03",
        ald_label: "Myélome multiple",
      },
      {
        ald_code: "ALD.30.01",
        ald_label:
          "Lymphome de Hodgkin, lymphome non hodgkinien, leucémie lymphoïde chronique, leucémie aiguë de l'adulte",
      },
      {
        ald_code: "ALD.30.10",
        ald_label: "Cancer de l'estomac",
      },
      {
        ald_code: "ALD.30.04",
        ald_label: "Cancer broncho-pulmonaires et mésothéliome pleural malin",
      },
      {
        ald_code: "ALD.30.05",
        ald_label: "Cancer invasif du col utérin",
      },
      {
        ald_code: "ALD.30.06",
        ald_label: "Cancer colorectal",
      },
      {
        ald_code: "ALD.30.09",
        ald_label: "Cancer de l'endomètre",
      },
      {
        ald_code: "ALD.30.13",
        ald_label: "Cancer de l'ovaire",
      },
      {
        ald_code: "ALD.30.14",
        ald_label: "Cancers du pancréas",
      },
      {
        ald_code: "ALD.30.07",
        ald_label: "Cancer des voies aérodigestives supérieures",
      },
      {
        ald_code: "ALD.30.08",
        ald_label: "Cancer primitif du système nerveux central",
      },
      {
        ald_code: "ALD.30.11",
        ald_label: "Cancer primitif du foie",
      },
      {
        ald_code: "ALD.30.12",
        ald_label: "Cancer de l'œsophage",
      },
      {
        ald_code: "ALD.30.20",
        ald_label: "Cancer de la vessie",
      },
      {
        ald_code: "ALD.30.16",
        ald_label: "Cancer du rein de l'adulte",
      },
      {
        ald_code: "ALD.30.18",
        ald_label: "Cancer du testicule",
      },
      {
        ald_code: "ALD.30.17",
        ald_label: "Cancer du sein",
      },
      {
        ald_code: "ALD.30.19",
        ald_label: "Cancer de la thyroïde",
      },
      {
        ald_code: "ALD.30.15",
        ald_label: "Cancer de la prostate",
      },
      {
        ald_code: "ALD.16",
        ald_label: "Maladie de Parkinson",
      },
      {
        ald_code: "ALD.18",
        ald_label: "Mucoviscidose ",
      },
      {
        ald_code: "ALD.19",
        ald_label:
          "Néphropathie chronique grave et syndrome néphrotique primitif",
      },
      {
        ald_code: "ALD.20",
        ald_label: "Paraplégie",
      },
      {
        ald_code: "ALD.01",
        ald_label: "Accident vasculaire cérébral invalidant ",
      },
      {
        ald_code: "ALD.02",
        ald_label: "Insuffisances médullaires et autres cytopénies chroniques",
      },
      {
        ald_code: "ALD.03",
        ald_label: "Artériopathies chroniques avec manifestations ischémique ",
      },
      {
        ald_code: "ALD.04",
        ald_label: "Bilharziose compliquée ",
      },
      {
        ald_code: "ALD.06.03",
        ald_label: "Hépatite chronique C",
      },
      {
        ald_code: "ALD.07",
        ald_label:
          "Déficit immunitaire primitif grave nécessitant un traitement prolongé, infection par le virus de l’immuno-déficience humaine (VIH)",
      },
      {
        ald_code: "ALD.08",
        ald_label: "Diabète de type 1 et diabète de type 2 ",
      },
      {
        ald_code: "ALD.10",
        ald_label:
          "Hémoglobinopathies, hémolyses, chroniques constitutionnelles et acquises sévères ",
      },
      {
        ald_code: "ALD.21.01",
        ald_label: "Lupus érythémateux systémique",
      },
      {
        ald_code: "ALD.21.02",
        ald_label: "Sclérodermie systémique",
      },
      {
        ald_code: "ALD.21.03",
        ald_label: "Vascularite",
      },
      {
        ald_code: "ALD.05",
        ald_label:
          "Insuffisance cardiaque grave, troubles du rythme graves, cardiopathies valvulaires graves, cardiopathies congénitales graves ",
      },
      {
        ald_code: "ALD.06.01",
        ald_label: "Cirrhoses",
      },
      {
        ald_code: "ALD.06.02",
        ald_label: "Hépatite chronique B",
      },
      {
        ald_code: "ALD.22",
        ald_label: "Polyarthrite rhumatoïde évolutive",
      },
      {
        ald_code: "ALD.24",
        ald_label: "Rectocolite hémorragique et maladie de Crohn évolutives",
      },
      {
        ald_code: "ALD.25",
        ald_label: "Sclérose en plaques ",
      },
      {
        ald_code: "ALD.14.01",
        ald_label: "Asthme",
      },
      {
        ald_code: "ALD.14.02",
        ald_label:
          "Insuffisance respiratoire chronique grave de l'adulte secondaire à une bronchopneumopathie chronique obstructive",
      },
      {
        ald_code: "ALD.27",
        ald_label: "Spondylarthrite ankylosante grave",
      },
      {
        ald_code: "ALD.28",
        ald_label: "Suites de transplantation d’organe",
      },
    ],
    clinical_drug_uri:
      "http://bdpm.graph.synapse-medicine.com#ClinicalDrug/33007a9050366191ba11ed313e0080b117fc6a2d",
    commercialization_status: "Commercialisée",
    ucd: "3400892794525",
    is_homeopathy: false,
    is_originator_informal: false,
    rcp_id: "64338986",
    is_biosimilar: false,
    schema_constraints_respected: true,
  };
  const dataMonograph = {
    researchEnabled: false,
    brandedDrug: drug,
  };
  const monographWidget = new window.Synapse.MonographWidget(JWT);

  const posologyCheckerWidget = new window.Synapse.PosologyCheckerWidget(JWT);

  const interactionWidget = new window.Synapse.InteractionWidget(JWT);

  useEffect(() => {
    autocompleteWidget.init(
      document.getElementById("autocomplete-container"),
      dataAutocomplete
    );
    autocompleteWidget.setCallback("onSelectEntity", (entity: any) => {
      console.log(entity);
      setEntities((prevEntities) => [...prevEntities, entity]);
    });
    prescriptionSummaryWidget.init(
      document.getElementById("prescription-summary-container"),
      data
    );
    allergyAutocomplete.init(
      document.getElementById("allergy-autocomplete-container"),
      {
        disabled: false,
      }
    );
    allergyAutocomplete.setCallback("onSelectAllergy", (allergy: any) => {
      console.log(allergy);
      setAllergies((prevAllergies) => [...prevAllergies, allergy]);
    });
    interactionWidget.init(
      document.getElementById("interaction-container"),
      data
    );
    interactionWidget.setCallback(
      "hasResults",
      function (hasResults: any, maxLevel: any) {
        console.log(hasResults);
        console.log(maxLevel);
      }
    );

    monographWidget.init(
      document.getElementById("monograph-container"),
      dataMonograph
    );

    posologyCheckerWidget.init(
      document.getElementById("posology-container"),
      data
    );
  }, []);

  useEffect(() => {
    setData((prevData: any) => ({
      ...prevData,
      entities: entities,
      profile: {
        ...prevData.profile,
        grounds: {
          ...prevData.profile.grounds,
          allergies: allergies,
        },
      },
    }));
    console.log(data);
    interactionWidget.update(data);
  }, [entities, allergies]);

  // useEffect(() => {
  //     console.log("restart");
  //     console.log(allergies);
  //     console.log(entities)
  //     const data = {
  //         entities: entities,
  //         profile: {
  //             grounds: {
  //               kidneyFailure: window.Synapse.ProfileType.KidneyProfile.LIGHT,
  //               hepaticFailure: window.Synapse.ProfileType.HepaticProfile.MODERATE,
  //               cardiacFailure: window.Synapse.ProfileType.CardiacProfile.HEAVY,
  //               allergies: allergies,
  //               comorbidities: [],
  //             },
  //             age: 23,
  //             height: 185,
  //             heightUnit: 'CM',
  //             weight: 75,
  //             weightUnit: 'KG',
  //             creatinine: {
  //               value: 83,
  //               unit: window.Synapse.ProfileType.CreatinineUnit.UMOL_L,
  //             },
  //             medicalHistory: [''],
  //             sex: window.Synapse.ProfileType.Sex.FEMALE,
  //             pregnant: true,
  //             pregnancyStartDate: new Date('2021-09-12'), // must be within the last nine months
  //             nursing: false,
  //             birthDate: new Date('2022-09-12'),
  //             lmp: new Date('2022-01-11'),
  //           }
  //        };
  //     prescriptionSummaryWidget.update(data);

  // }, [entities, allergies])

  useEffect(() => {
    //Widget PrescriptionSummary

    console.log(entities);

    //Widget Autocomplete Allergies

    //   autocompleteWidget.destroy()
  }, []);

  window.Synapse.endContext();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "32px",
        flexDirection: "column",
        padding: "32px",
        backgroundColor: "white",
      }}
    >
      <div id="autocomplete-container"></div>
      {entities.map((entity: any) => {
        return (
          <div style={{ color: "black" }} key={entity.drug_id}>
            {entity.shortname}
          </div>
        );
      })}
      <div id="allergy-autocomplete-container"></div>
      {allergies.map((allergy: any) => {
        return (
          <div style={{ color: "black" }} key={allergy.uri}>
            {allergy.label}
          </div>
        );
      })}
      <div id="posology-container"></div>
      <div id="interaction-container"></div>
      <div id="prescription-summary-container"></div>
      <div
        id="monograph-container"
        style={{
          overflow: "scroll",
          backgroundColor: "white",
          height: "500px",
          width: "400px",
        }}
      ></div>
    </div>
  );
}
