import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import { Radio, Select, Space } from "antd";
import { Report } from "../../types/Reports";

export const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

Chart.register(CategoryScale, LinearScale, BarElement, Title);

interface Props {
  reports: Report[];
}
type DataReport = {
  type: string;
  number: number;
};
const today = new Date();

export default function BarGraph(props: Props) {
  const [currentYear, setCurrentYear] = useState<string>(
    today.getFullYear().toString()
  );
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: "Nombre de reports",
        data: [],
        backgroundColor: ["rgba(75, 192, 192, 1)"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  const [duration, setDuration] = useState<"day" | "month">("day");

  const ChangeGraphDuration = () => {
    const handleSizeChange = (e: any) => {
      setDuration(e.target.value);
    };
    return (
      <>
        <Radio.Group value={duration} onChange={handleSizeChange}>
          <Radio.Button value="day">Nouveaux par jour</Radio.Button>
          <Radio.Button value="month">Nouveaux par mois</Radio.Button>
        </Radio.Group>
        <br />
        <br />
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        ></Space>
      </>
    );
  };

  // useEffect qui recupère les 6 derniers mois pour en faire un tableau d'objet à partir de MONTHS
  useEffect(() => {
    duration === "day" ? loadByDay() : loadByMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reports, duration, currentYear]);

  const loadByMonth = () => {
    // Si on veut l'année entière
    let lastYear: number[] = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

    // On initialse les mois, avec 0 en nombre de report
    let tmpDataReport: DataReport[] = lastYear.map((month) => {
      return {
        type: MONTHS[month],
        number: 0,
      };
    });

    // On parcourt les reports pour incrémenter les compteurs de notre objet tmpData
    props.reports?.forEach((report: Report) => {
      const parts = report.created_at.split("/");
      const nbMonth = parseInt(parts[1], 10); // Parsez le mois en tant que nombre
      const nbYear = parts[2].slice(0, 4); // Prendre l'année
      if (lastYear.includes(nbMonth - 1) && nbYear === currentYear) {
        let foundedIndex = tmpDataReport.findIndex(
          (report) => report.type === MONTHS[nbMonth - 1]
        );
        tmpDataReport[foundedIndex].number =
          tmpDataReport[foundedIndex].number + 1;
      }
      setChartData({
        labels: tmpDataReport.map((item) => item.type).reverse(),
        datasets: [
          {
            label: "Nombre de reports",
            data: tmpDataReport.map((item) => item.number).reverse(),
            backgroundColor: ["rgba(75, 192, 192, 1)"],
          },
        ],
      });
    });
  };

  const loadByDay = () => {
    const countByDate: { [key: string]: number } = {};
    // Compter les objets par date de création
    props.reports.reverse().forEach((report: Report) => {
      const date = report.created_at.slice(0, 5); // Les 10 premiers caractères représente la date JJ/MM/AAAA
      // Si le tableau countByDate contient déjà la date, alors on incrémente
      if (countByDate[date]) countByDate[date]++;
      // Sinon on met à 1
      else countByDate[date] = 1;
    });
    // On crée notre tableau d'objet DataReports.
    // Object.entries : prend un objet en argument et renvoie un tableau contenant des objets de paires clé-valeur de cet objet.
    const data: DataReport[] = Object.entries(countByDate).map(
      ([key, value]) => {
        return {
          type: key,
          number: value,
        };
      }
    );

    setChartData({
      labels: data.map((item) => item.type),
      datasets: [
        {
          label: "Nombre de reports",
          data: data.map((item) => item.number),
          backgroundColor: ["rgba(75, 192, 192, 1)"],
        },
      ],
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginBottom: 20,
          gap: 15,
        }}
      >
        <Select
          onChange={(value: string) => setCurrentYear(value)}
          value={currentYear}
          options={[
            { value: "2023", label: 2023 },
            { value: "2024", label: 2024 },
          ]}
        />
        {ChangeGraphDuration()}
      </div>

      <Bar
        data={chartData}
        style={{ maxHeight: 200 }}
        options={{
          // responsive: true,
          plugins: {
            title: {
              display: true,
            },
          },
        }}
      />
    </>
  );
}
