import { useContext } from "react";
import { ProfessionalsResponse } from "../types/Professionals";
import { EMPTY_UUID } from "../utils/formatting";
import Context from "../context/Context";

export default function useAvatar() {
  const { apiThianaClient } = useContext(Context);
  const getFileAvatarByAvatarID = async (avatarID: string) => {
    let getFileResponse: any;
    let response = await apiThianaClient.apiStorages.getFileRaw({
      id: avatarID,
    });
    if (response.raw.status === 200) {
      return URL.createObjectURL(getFileResponse);
    }
    return "";
  };
  const getFileAvatarByProfessionalID = async (professionalID: string) => {
    let getFileResponse: any;
    let professionalsResponse: ProfessionalsResponse;
    let response = await apiThianaClient.apiProfessional.professionalGetOneRaw({
      id: professionalID,
    });
    if (response.raw.status === 200) {
      professionalsResponse = await response.raw.json();
      if (professionalsResponse.data[0].avatar !== EMPTY_UUID) {
        let response2 = await apiThianaClient.apiStorages.getFileRaw({
          id: professionalsResponse.data[0].avatar,
        });
        if (response2.raw.status === 200) {
          getFileResponse = await response2.raw.blob();
          return {
            responseStatus: 200,
            fileAvatar: URL.createObjectURL(getFileResponse),
          };
        }
        return { responseStatus: response2.raw.status };
      }
    }
    return { responseStatus: response.raw.status };
  };

  return { getFileAvatarByAvatarID, getFileAvatarByProfessionalID };
}
