import {
  AudioFilled,
  AudioOutlined,
  CheckCircleFilled,
  FilterOutlined,
  MedicineBoxOutlined,
  RestFilled,
  SettingOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Button, Menu, MenuProps } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBackAppStatusByEnv } from "../../api/api";
import FiltersModal from "../filters/FiltersModal";
import Context from "../../context/Context";
import { getFormattedGlobal, showS } from "../../utils/formatting";
import styled from "styled-components";
import { colors } from "../../theme/thiana";

interface StatusIconProps {
  $status: boolean;
}

interface Props {
  page: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
}

type EnvStates = {
  development: boolean;
  preview: boolean;
  production: boolean;
};

const initialEnvStates = {
  development: false,
  preview: false,
  production: false,
};

export default function MainMenu(props: Props) {
  const [envStates, setEnvStates] = useState<EnvStates>(initialEnvStates);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const { activeUserFilters, updateActiveUserFilters } = useContext(Context);
  let navigate = useNavigate();
  let { environment } = useParams();

  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  useEffect(() => {
    (async () => {
      let stateDev = false;
      let statePreview = false;
      let stateProduction = false;
      try {
        const responseDev = await getBackAppStatusByEnv("development");
        if (responseDev.status === 200) stateDev = true;
      } catch (error) {
        console.error("Environnement de développment DOWN");
      }
      try {
        const responsePreview = await getBackAppStatusByEnv("preview");
        if (responsePreview.status === 200) statePreview = true;
      } catch (error) {
        console.error("Environnement de préproduction DOWN");
      }
      try {
        const responseProduction = await getBackAppStatusByEnv("production");
        if (responseProduction.status === 200) stateProduction = true;
      } catch (error) {
        console.error("Environnement de production DOWN");
      }

      setEnvStates({
        development: stateDev,
        preview: statePreview,
        production: stateProduction,
      });
    })();
  }, []);

  const items: MenuProps["items"] = [
    getItem("GLOBAL", "global", <SettingOutlined />, [
      getItem("Users", "users"),
      getItem("Professionals", "professionals"),
      getItem("Workspaces", "workspaces"),
      getItem("Customers", "customers"),
      getItem("Subscriptions", "subscriptions"),
    ]),

    getItem("THIANA AI", "thiana-ai", <AudioOutlined />, [
      getItem("Reports", "reports"),
    ]),

    getItem("THIANA DPI", "thiana-dpi", <AudioOutlined />, [
      getItem("Patients", "patients"),
    ]),

    getItem("AI Lab", "", <StarFilled />, [
      getItem("LLM Testing", "llm-testing", <StarFilled />),
      getItem("ASR Testing", "asr-testing", <AudioFilled />),
    ]),
    getItem("Test Synapse", "test-synapse", <MedicineBoxOutlined />),
  ];

  const handleMenuClick = (key: string) => {
    switch (key) {
      case "users":
        navigate("/" + environment + "/users");
        break;
      case "professionals":
        navigate("/" + environment + "/professionals");
        break;
      case "workspaces":
        navigate("/" + environment + "/workspaces");
        break;
      case "customers":
        navigate("/" + environment + "/customers");
        break;
      case "subscriptions":
        navigate("/" + environment + "/subscriptions");
        break;
      case "patients":
        navigate("/" + environment + "/patients");
        break;
      case "documents":
        navigate("/" + environment + "/documents");
        break;
      case "reports":
        navigate("/" + environment + "/reports");
        break;
      case "models":
        navigate("/" + environment + "/models");
        break;
      case "llm-testing":
        navigate("/" + environment + "/llm-testing");
        break;
      case "asr-testing":
        navigate("/" + environment + "/asr-testing");
        break;
      case "test-synapse":
        navigate("/" + environment + "/test-synapse");
        break;
      default:
        break;
    }
    localStorage.setItem("page", key);
    props.setPage(key);
  };

  return (
    <MenuStyled>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 5,
        }}
      >
        <div style={{ display: "flex", gap: 5 }}>
          <Button
            onClick={() => setOpenFilterModal(true)}
            style={{ marginBottom: 10, flex: 1 }}
            icon={<FilterOutlined />}
          >
            {activeUserFilters.length} filtre{showS(activeUserFilters.length)}{" "}
            {getFormattedGlobal(activeUserFilters.length)}
          </Button>
          <Button
            onClick={() => updateActiveUserFilters([])}
            style={{ marginBottom: 10 }}
            icon={<RestFilled />}
          ></Button>
        </div>

        <Menu
          onClick={(e) => handleMenuClick(e.key)}
          style={{ width: 256, border: "none" }}
          selectedKeys={[props.page]}
          defaultOpenKeys={["global", "thiana-ai"]}
          mode="inline"
          items={items}
        />
        <FiltersModal
          openFilterModal={openFilterModal}
          setOpenFilterModal={setOpenFilterModal}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 5,
          justifyContent: "space-evenly",
        }}
      >
        <div>
          DEV{" "}
          <StatusIcon $status={envStates.development}>
            <CheckCircleFilled />
          </StatusIcon>
        </div>
        <div>
          PREPROD{" "}
          <StatusIcon $status={envStates.preview}>
            <CheckCircleFilled />
          </StatusIcon>
        </div>
        <div>
          PROD{" "}
          <StatusIcon $status={envStates.production}>
            <CheckCircleFilled />
          </StatusIcon>
        </div>
      </div>
    </MenuStyled>
  );
}

const MenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StatusIcon = styled.span<StatusIconProps>`
  color: ${(props) =>
    props.$status ? colors.palette.success[700] : colors.palette.error[800]};
`;
