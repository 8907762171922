import { Breadcrumb, Flex, Pagination } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { ThianaPagination } from "../../types/Pagination";
import Search from "antd/es/input/Search";
import Context from "../../context/Context";
export interface CustomerDataSource extends Customer {
  key: string;
}
type Customer = {
  id: string;
  professional_id: string;
  created_at: string;
  customer_external_id: string;
  updated_at: string;
};

export default function Customers() {
  const [searchValue, setSearchValue] = useState<string>("");
  const [customersDataSource, setCustomersDataSource] = useState<
    CustomerDataSource[]
  >([]);
  const [customersPagination, setCustomersPagination] =
    useState<ThianaPagination>({
      items: 10,
      currentPage: 1,
      nbPages: undefined,
      totalItems: undefined,
    });

  const { apiThianaClient } = useContext(Context);

  const fetchCustomers = async () => {
    let response = await apiThianaClient.apiPayments.customerGetAllRaw({
      items: customersPagination.items,
      page: customersPagination.currentPage,
    });
    if (response.raw.status === 200) {
      let customers: any = await response.raw.json();
      setCustomersPagination({
        ...customersPagination,
        totalItems: customers.metadata?.total,
        nbPages: customers.metadata?.pages,
      });
      let tmp = customers.data.map((customer: any, index: number) => {
        return { ...customer, key: index };
      });
      setCustomersDataSource(tmp);
    }
  };

  const fetchCustomersByProfessionalID = async () => {
    let filters = "professional_id:" + searchValue;
    let response = await apiThianaClient.apiPayments.customerGetAllRaw({
      items: customersPagination.items,
      page: customersPagination.currentPage,
      equal: filters,
    });
    if (response.raw.status === 200) {
      let customers: any = await response.raw.json();
      let tmp = customers.data.map((customer: any, index: number) => {
        return { ...customer, key: index };
      });
      setCustomersDataSource(tmp);
    }
  };

  // useEffect qui permet de récupérer les reports (avec ou sans filtre)
  useEffect(() => {
    if (searchValue.length >= 3) fetchCustomersByProfessionalID();
    else if (searchValue.length === 0) fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersPagination.currentPage, searchValue]);

  const columns: ColumnsType<CustomerDataSource> = [
    {
      title: <code style={{ fontSize: 10 }}>id</code>,
      dataIndex: "id",
      key: "id",

      render: (id: string) => <div style={{ fontSize: 10 }}>{id}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>professional_id</code>,
      dataIndex: "professional_id",
      key: "professional_id",

      render: (professional_id: string) => (
        <div style={{ fontSize: 10 }}>{professional_id}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>customer_external_id</code>,
      dataIndex: "customer_external_id",
      key: "customer_external_id",

      render: (customer_external_id: string) => (
        <div style={{ fontSize: 10 }}>{customer_external_id}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>created_at</code>,
      dataIndex: "created_at",
      key: "created_at",

      render: (created_at: string) => (
        <div style={{ fontSize: 10 }}>{created_at}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>updated_at</code>,
      dataIndex: "updated_at",
      key: "updated_at",

      render: (updated_at: string) => (
        <div style={{ fontSize: 10 }}>{updated_at}</div>
      ),
    },
  ];

  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Global",
            },
            {
              title: "Customers",
            },
          ]}
        />
      </div>
      <Flex vertical gap={"small"} style={{ marginTop: 10 }}>
        <Search
          placeholder="Rechercher des customers par professional_id ..."
          allowClear
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div
          style={{
            border: "1px solid rgba(128, 128, 128, 0.5)",
            borderRadius: "5px",
          }}
        >
          <Table
            columns={columns}
            dataSource={customersDataSource}
            pagination={false}
          />
          <Pagination
            style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
            defaultCurrent={customersPagination.currentPage}
            current={customersPagination.currentPage}
            total={customersPagination.totalItems}
            onChange={(newPage) =>
              setCustomersPagination({
                ...customersPagination,
                currentPage: newPage,
              })
            }
            showSizeChanger={false}
            showTotal={(total) => (
              <span style={{ fontSize: 12, color: "grey" }}>
                {total} customers au total
              </span>
            )}
          />
        </div>
      </Flex>
    </div>
  );
}
