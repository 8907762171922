const getFormattedEnvironment = (env: string) => {
  switch (env) {
    case "development":
      return "DEV";
    case "preview":
      return "PREPROD";
    case "production":
      return "PROD";
    default:
      return "Erreur environnement";
  }
};

export { getFormattedEnvironment };
