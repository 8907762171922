import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
const pageLS = localStorage.getItem("page");

export default function ViewController() {
  let navigate = useNavigate();
  let { environment } = useParams();
  useEffect(() => {
    if (pageLS) navigate("/" + environment + "/" + pageLS);
    else navigate("/" + environment + "/users");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
