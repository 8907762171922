import Table, { ColumnsType } from "antd/es/table";
import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useContext, useState } from "react";
import { UserDataSource } from "./Users";
import DrawerListUsers from "./DrawerUsers/DrawerListUsers";
import useActiveUserFilters from "../../hooks/useActiveUserFilters";
import Context from "../../context/Context";
import React from "react";
import colors from "../../theme/thiana";
import { PaginationList } from "../../types/Users";
import { Modal, Pagination, Typography } from "antd";
import { UserOutput } from "@thiana/api-thiana-client";

interface Props {
  usersDataSource: UserDataSource[];
  setUsersDataSource: React.Dispatch<React.SetStateAction<UserDataSource[]>>;
  pagination: PaginationList;
  setPagination: React.Dispatch<React.SetStateAction<PaginationList>>;
  nbTotalUsers?: number;
}

export default function UsersList(props: Props) {
  const [open, setOpen] = useState(false);
  const [idToActive, setIdToActive] = useState<string>("");
  const [openModalIsActive, setOpenModalIsActive] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDataSource | null>(null);
  const { activeUserFilters, apiThianaClient } = useContext(Context);
  let { isInActiveFiltersByProfessionalID } = useActiveUserFilters();

  const showDrawer = (user: UserDataSource) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const updateUserList = (updatedUser: UserDataSource) => {
    const updatedList = props.usersDataSource.map((user) => {
      if (user.id === updatedUser.id) {
        return updatedUser;
      }
      return user;
    });
    props.setUsersDataSource(updatedList);
  };

  // Activer le user (is_active à true)
  const activeUser = async () => {
    // PUT en base de donnée sur le user
    // let response = await apiThianaClient.apiUsers.userPatchRaw();
    // if (response.raw.status === 200) {
    if (true) {
      console.log("test");
      // let userOutput: UserOutput = await response.raw.json();
      // Mis-à-jour du tableau
      const updatedList = props.usersDataSource.map((user) => {
        if (user.id === idToActive) {
          return { ...user, is_active: !user.is_active };
        }
        return user;
      });
      props.setUsersDataSource(updatedList);
    }
  };

  const convertDateIntoTimestamp = (dateString: string) => {
    // Diviser la chaîne en parties pour obtenir les éléments de la date
    let dateParts = dateString.split(" ");
    let date = dateParts[0].split("/");
    let time = dateParts[1].split(":");
    // Créer un objet Date
    let jsDate = new Date(
      parseInt(date[2]),
      parseInt(date[1]) - 1,
      parseInt(date[0]),
      parseInt(time[0]),
      parseInt(time[1]),
      parseInt(time[2])
    );

    // Obtenir le timestamp en seconde
    return jsDate.getTime() / 1000;
  };

  const columns: ColumnsType<UserDataSource> = [
    {
      title: <code style={{ fontSize: 10 }}>first_name</code>,
      dataIndex: "first_name",
      key: "first_name",
      fixed: "left",
      width: 100,
      render: (first_name: string) => (
        <div style={{ fontSize: 10 }}>{first_name}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>last_name</code>,
      dataIndex: "last_name",
      key: "last_name",
      fixed: "left",
      width: 100,
      render: (last_name: string) => (
        <div style={{ fontSize: 10 }}>{last_name}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>email</code>,
      dataIndex: "email",
      key: "email",
      width: 150,
      render: (email: string) => <div style={{ fontSize: 10 }}>{email}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>user_id</code>,
      dataIndex: "id",
      key: "id",
      width: 150,
      render: (user_id: string) => (
        <div style={{ fontSize: 10 }}>{user_id}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>professional_id</code>,
      dataIndex: "professional_id",
      key: "professional_id",
      width: 150,
      render: (professional_id: string) => (
        <div style={{ fontSize: 10 }}>{professional_id}</div>
      ),
    },

    {
      title: <code style={{ fontSize: 10 }}>created_at</code>,
      dataIndex: "created_at",
      key: "created_at",
      width: 125,
      sorter: (a, b) =>
        convertDateIntoTimestamp(a.created_at) -
        convertDateIntoTimestamp(b.created_at),
      render: (created_at: string) => (
        <div style={{ fontSize: 10 }}>{created_at}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>is_active</code>,
      dataIndex: "is_active",
      key: "is_active",
      width: 75,
      render: (_, record) => (
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            setIdToActive(record.id);
            setOpenModalIsActive(true);
          }}
        >
          {record.is_active ? (
            <CheckOutlined style={{ color: colors.palette.success[600] }} />
          ) : (
            <CloseOutlined style={{ color: colors.palette.danger[600] }} />
          )}
        </div>
      ),
    },
    {
      title: <div style={{ fontSize: 10 }}>profil complet</div>,
      key: "completed",
      width: 90,
      render: (item: UserDataSource) => (
        <div style={{ textAlign: "center" }}>
          {item.first_name &&
          item.last_name &&
          item.birth_date &&
          item.professional_id ? (
            <CheckOutlined style={{ color: colors.palette.success[600] }} />
          ) : (
            <CloseOutlined style={{ color: colors.palette.danger[600] }} />
          )}
        </div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>updated_at</code>,
      dataIndex: "updated_at",
      key: "updated_at",
      width: 125,
      sorter: (a, b) =>
        convertDateIntoTimestamp(a.created_at) -
        convertDateIntoTimestamp(b.created_at),
      render: (updated_at: string) => (
        <div style={{ fontSize: 10 }}>{updated_at}</div>
      ),
    },

    {
      title: <div style={{ fontSize: 10 }}>actions</div>,
      key: "action",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          <EllipsisOutlined onClick={() => showDrawer(record)} />
        </div>
      ),
      fixed: "right",
      width: 60,
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Modal
        open={openModalIsActive}
        onCancel={() => {
          setOpenModalIsActive(false);
          setIdToActive("");
        }}
        onOk={() => {
          activeUser();
          setOpenModalIsActive(false);
          setIdToActive("");
        }}
        okText={"Activer"}
        cancelText={"Annuler"}
        title={"Activation du compte"}
      >
        <Typography>
          Êtes-vous sûr de vouloir activer/désactiver ce compte ?
        </Typography>
      </Modal>
      <div
        style={{
          border: "1px solid rgba(128, 128, 128, 0.5)",
          borderRadius: "5px",
        }}
      >
        <Table
          columns={columns}
          dataSource={props.usersDataSource.filter(
            (userDataSource: UserDataSource) =>
              activeUserFilters.length > 0
                ? isInActiveFiltersByProfessionalID(
                    userDataSource.professional_id
                  )
                : true
          )}
          pagination={false}
          scroll={{ x: 1300 }}
        />
        <Pagination
          style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
          defaultCurrent={props.pagination.currentPage}
          current={props.pagination.currentPage}
          total={props.nbTotalUsers}
          onChange={(newPage) =>
            props.setPagination({ ...props.pagination, currentPage: newPage })
          }
          showSizeChanger={false}
          showTotal={(total) => (
            <span style={{ fontSize: 12, color: colors.palette.grey[600] }}>
              {total} utilisateurs au total
            </span>
          )}
        />
      </div>
      <DrawerListUsers
        open={open}
        setOpen={setOpen}
        usersDataSource={props.usersDataSource}
        setUsersDataSource={props.setUsersDataSource}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        updateUserList={updateUserList}
        // updateUserList={updateUserList}
      />
    </div>
  );
}
