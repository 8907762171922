import {
  AuthApi,
  Configuration,
  ResponseContext,
  RequestContext,
  UsersApi,
  ReferentialsApi,
  StoragesApi,
  ProfessionalApi,
  ReportsApi,
  ReportSnapshotsApi,
  ProfessionalsApi,
  PaymentsApi,
  PatientsApi,
} from "@thiana/api-thiana-client";
import { postMiddleware } from "./PostMiddleware";
import { preMiddleware } from "./PreMiddleware";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
export interface ApiThianaClient {
  apiAuth: AuthApi;
  apiUsers: UsersApi;
  apiStorages: StoragesApi;
  apiReports: ReportsApi;
  apiReportSnapshots: ReportSnapshotsApi;
  apiProfessional: ProfessionalApi;
  apiProfessionals: ProfessionalsApi;
  apiReferentials: ReferentialsApi;
  apiPatients: PatientsApi;
  apiPayments: PaymentsApi;
}

/**
 * Récupère l'url du back sur lequel on veut effectuer la requête
 * @param paramEnv
 * @returns
 */
const getClientUrl = (
  paramEnv?: "development" | "preview" | "production" | "audit"
) => {
  let env: "development" | "preview" | "production" | "audit" | string | null;
  if (paramEnv) env = paramEnv;
  else env = localStorage.getItem("env");
  switch (env) {
    case "development":
      return process.env.REACT_APP_URL_BACKAPP_CLIENT_DEVELOPMENT;
    case "preview":
      return process.env.REACT_APP_URL_BACKAPP_CLIENT_PREVIEW;
    case "production":
      return process.env.REACT_APP_URL_BACKAPP_CLIENT_PRODUCTION;
    case "audit":
      return process.env.REACT_APP_URL_BACKAPP_CLIENT_AUDIT;
    default:
      return process.env.REACT_APP_URL_BACKAPP_CLIENT_DEVELOPMENT;
  }
};
interface Props {
  env: "development" | "preview" | "production" | "audit";
}
export default function useApiThianaClient(props: Props): ApiThianaClient {
  let { environment } = useParams();

  // Configuration par défaut : pointe sur l'environnement de dév
  const defaultConfig = new Configuration({
    basePath: getClientUrl(environment as any),
    middleware: [
      {
        pre: async (context: RequestContext) => {
          await preMiddleware(context);
        },

        post: async (context: ResponseContext) => {
          await postMiddleware(context);
        },
      },
    ],
  });
  // Déclaration des différents services API
  const [apiAuth, setApiAuth] = useState(new AuthApi(defaultConfig));
  const [apiUsers, setApiUsers] = useState(new UsersApi(defaultConfig));
  const [apiProfessional, setApiProfessional] = useState(
    new ProfessionalApi(defaultConfig)
  );
  const [apiProfessionals, setApiProfessionals] = useState(
    new ProfessionalsApi(defaultConfig)
  );
  const [apiReferentials, setApiReferentials] = useState(
    new ReferentialsApi(defaultConfig)
  );
  const [apiStorages, setApiStorages] = useState(
    new StoragesApi(defaultConfig)
  );
  const [apiReports, setApiReports] = useState(new ReportsApi(defaultConfig));
  const [apiReportSnapshots, setApiReportSnapshots] = useState(
    new ReportSnapshotsApi(defaultConfig)
  );
  const [apiPayments, setApiPayments] = useState(
    new PaymentsApi(defaultConfig)
  );

  const [apiPatients, setApiPatients] = useState(
    new PatientsApi(defaultConfig)
  );

  // Mise à jour de la configuratio API lors du changement d'environment
  useEffect(() => {
    let config = new Configuration({
      basePath: getClientUrl(props.env),
      middleware: [
        {
          pre: async (context: RequestContext) => {
            await preMiddleware(context);
          },
          post: async (context: ResponseContext) => {
            await postMiddleware(context);
          },
        },
      ],
    });
    setApiAuth(new AuthApi(config));
    setApiUsers(new UsersApi(config));
    setApiProfessional(new ProfessionalApi(config));
    setApiProfessionals(new ProfessionalsApi(config));
    setApiReferentials(new ReferentialsApi(config));
    setApiStorages(new StoragesApi(config));
    setApiReports(new ReportsApi(config));
    setApiReportSnapshots(new ReportSnapshotsApi(config));
    setApiPayments(new PaymentsApi(config));
    setApiPatients(new PatientsApi(config));
  }, [props.env]);

  return {
    apiAuth,
    apiProfessional,
    apiProfessionals,
    apiReferentials,
    apiUsers,
    apiStorages,
    apiReports,
    apiReportSnapshots,
    apiPayments,
    apiPatients,
  };
}
