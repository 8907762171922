import {
  BlockOutlined,
  ClearOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  FullscreenOutlined,
  PlusCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Input, InputNumber, Modal, Select } from "antd";
import React, { useContext, useState } from "react";
import AIContext from "../../../../../context/AIContext";
import useAILab from "../../../../../hooks/useAILab";
import {
  BlockType,
  PromptItem,
  VariableType,
} from "../../../../../types/AITypes";

export default function Block(props: BlockType) {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const { blocks, updateBlocks, variables } = useContext(AIContext);
  const { launchBlockByID } = useAILab();

  const onChangeBlockData = (
    type: string,
    value?: string,
    prompt?: PromptItem[]
  ) => {
    let currentBlock = blocks.find(
      (data: BlockType) => data.id === props.id
    ) as BlockType;
    let updatedBlock: BlockType;
    let updatedBlocks = [...blocks];
    switch (type) {
      case "title":
        updatedBlock = { ...currentBlock, title: value as string };
        updatedBlocks.splice(blocks.indexOf(currentBlock), 1, updatedBlock);
        updateBlocks(updatedBlocks);
        break;
      case "input":
        updatedBlock = { ...currentBlock, input: value as string };
        updatedBlocks.splice(blocks.indexOf(currentBlock), 1, updatedBlock);
        updateBlocks(updatedBlocks);
        break;
      case "prompt":
        updatedBlock = { ...currentBlock, prompt: prompt as PromptItem[] };
        updatedBlocks.splice(blocks.indexOf(currentBlock), 1, updatedBlock);
        updateBlocks(updatedBlocks);
        break;
      case "outputOperation":
        updatedBlock = {
          ...currentBlock,
          outputOperation: value as "add" | "replace",
        };
        updatedBlocks.splice(blocks.indexOf(currentBlock), 1, updatedBlock);
        updateBlocks(updatedBlocks);
        break;
      case "outputVariable":
        updatedBlock = { ...currentBlock, outputVariable: value as string };
        updatedBlocks.splice(blocks.indexOf(currentBlock), 1, updatedBlock);
        updateBlocks(updatedBlocks);
        break;
      default:
        break;
    }
  };

  const deleteBlock = () => {
    let blocksCopy = [...blocks];
    let currentBlock: BlockType = blocks.find(
      (data: BlockType) => data.id === props.id
    ) as BlockType;
    blocksCopy.splice(blocks.indexOf(currentBlock), 1);
    updateBlocks(blocksCopy);
  };

  const moveIndex = (type: "plus" | "minus") => {
    let blocksCopy = [...blocks];
    let currentBlock: BlockType = blocks.find(
      (data: BlockType) => data.id === props.id
    ) as BlockType;
    blocksCopy.splice(blocks.indexOf(currentBlock), 1);
    if (type === "plus") {
      blocksCopy.splice(blocks.indexOf(currentBlock) - 1, 0, currentBlock);
    } else {
      blocksCopy.splice(blocks.indexOf(currentBlock) + 1, 0, currentBlock);
    }
    updateBlocks(blocksCopy);
  };

  const switchPromptItemType = (index: number) => {
    const itemToChange = props.prompt[index];
    if (itemToChange.role === "user") {
      itemToChange.role = "assistant";
      delete itemToChange.conditionData;
    } else if (itemToChange.role === "assistant") {
      itemToChange.role = "condition";
      itemToChange.conditionData = {
        conditionString: "",
        continue: false,
      };
    } else {
      itemToChange.role = "user";
      delete itemToChange.conditionData;
    }
    const newPrompts = [...props.prompt];
    newPrompts.splice(index, 1, itemToChange);
    onChangeBlockData("prompt", undefined, newPrompts);
  };

  const addPromptItem = () => {
    const newPrompts = [...props.prompt];
    const lastItem = newPrompts[newPrompts.length - 1];
    if (lastItem.role === "assistant")
      newPrompts.push({ role: "user", content: "", maxNewTokens: null });
    else
      newPrompts.push({
        role: "assistant",
        content: "",
        maxNewTokens: null,
      });
    onChangeBlockData("prompt", undefined, newPrompts);
  };

  const deletePromptItem = (index: number) => {
    const newPrompts = [...props.prompt];
    newPrompts.splice(index, 1);
    if (props.prompt.length > 1)
      onChangeBlockData("prompt", undefined, newPrompts);
  };

  const updatePromptValue = (index: number, value: string) => {
    const currentPrompt = props.prompt[index];
    currentPrompt.content = value;
    const newPrompts = [...props.prompt];
    newPrompts.splice(index, 1, currentPrompt);
    onChangeBlockData("prompt", undefined, newPrompts);
  };

  const updateConditionString = (index: number, conditionString: string) => {
    const itemToChange = props.prompt[index] as PromptItem;
    if (itemToChange.conditionData)
      itemToChange.conditionData.conditionString = conditionString;
    const newPrompts = [...props.prompt];
    newPrompts.splice(index, 1, itemToChange);
    onChangeBlockData("prompt", undefined, newPrompts);
  };

  const switchConditionValue = (index: number) => {
    const itemToChange = props.prompt[index] as PromptItem;
    if (itemToChange.conditionData)
      itemToChange.conditionData.continue = !itemToChange.conditionData
        .continue as boolean;
    const newPrompts = [...props.prompt];
    newPrompts.splice(index, 1, itemToChange);
    onChangeBlockData("prompt", undefined, newPrompts);
  };

  const setMaxNewTokens = (value: number | null, index: number) => {
    const itemToChange = props.prompt[index] as PromptItem;
    itemToChange.maxNewTokens = value;
    const newPrompts = [...props.prompt];
    newPrompts.splice(index, 1, itemToChange);
    onChangeBlockData("prompt", undefined, newPrompts);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        backgroundColor: "black",
        // border: "1px solid #545454",
        padding: "8px",
        borderRadius: "8px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <Input
          value={props.title}
          style={{ fontSize: "22px", fontWeight: "bold" }}
          onChange={(e: any) => onChangeBlockData("title", e.target.value)}
        />
        <Button
          size="large"
          icon={<UpOutlined />}
          onClick={() => moveIndex("plus")}
        />
        <Button
          size="large"
          icon={<DownOutlined />}
          onClick={() => moveIndex("minus")}
        />
        <Button
          size="large"
          danger
          icon={<DeleteOutlined />}
          onClick={() => setIsDeleteModalShown(true)}
        />
        <Modal
          title="Supprimer le block"
          open={isDeleteModalShown}
          onOk={deleteBlock}
          onCancel={() => setIsDeleteModalShown(false)}
        >
          <p>Supprimer définitivement le block</p>
        </Modal>
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          gap: 4,
        }}
      >
        {props.prompt.map((item: PromptItem, index: number) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                gap: 2,
                borderRadius: 4,
                padding: 2,
                backgroundColor:
                  item.role === "assistant"
                    ? "#021c09"
                    : item.role === "user"
                    ? "#03022b"
                    : "#2b0502",
              }}
            >
              <Button onClick={() => switchPromptItemType(index)}>
                {item.role}
              </Button>
              {item.role !== "condition" ? (
                <>
                  <Input.TextArea
                    value={item.content}
                    onChange={(e) => updatePromptValue(index, e.target.value)}
                  />
                  {item.role !== "assistant" ? (
                    <InputNumber
                      defaultValue={512}
                      value={item.maxNewTokens}
                      onChange={(value) => setMaxNewTokens(value, index)}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <Input.TextArea
                    value={item.conditionData?.conditionString}
                    onChange={(e) =>
                      updateConditionString(index, e.target.value)
                    }
                  />
                  <Button onClick={() => switchConditionValue(index)}>
                    {item.conditionData?.continue === true ? "True" : "False"}
                  </Button>
                </>
              )}

              <Button
                onClick={() => deletePromptItem(index)}
                icon={<CloseCircleOutlined />}
              ></Button>
            </div>
          );
        })}
        <Button block icon={<PlusCircleOutlined />} onClick={addPromptItem}>
          Ajouter
        </Button>
        {/* <Input.TextArea
          value={props.prompt}
          onChange={(e: any) => onChangeBlockData("prompt", e.target.value)}
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {/* <Button type="link" icon={<FullscreenOutlined />}>
            Agrandir
          </Button> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: 0,
          }}
        >
          Output :
        </p>
        <Select
          style={{ width: "100%" }}
          defaultValue={props.outputOperation}
          onSelect={(value) => onChangeBlockData("outputOperation", value)}
          options={[
            { value: "add", label: "add" },
            { value: "replace", label: "replace" },
          ]}
        />
        <Select
          style={{ width: "100%" }}
          defaultValue={props.outputVariable}
          onSelect={(value) => onChangeBlockData("outputVariable", value)}
          options={[
            ...variables.map((variable: VariableType) => {
              return { value: variable.id, label: variable.name };
            }),
            { value: "outputText", label: "outputText" },
            { value: "continue", label: "continue" },
          ]}
        />
      </div>
      <Button block type="default" onClick={() => launchBlockByID(props.id)}>
        Launch block
      </Button>
      <span style={{ color: "rgba(255,255,255,0.3" }}>id: {props.id}</span>
    </div>
  );
}
