import { useContext, useEffect, useState } from "react";
import Breadcrumb from "antd/es/breadcrumb";
import UsersList from "./UsersList";
import { PaginationList } from "../../types/Users";
import Context from "../../context/Context";
import useActiveUserFilters from "../../hooks/useActiveUserFilters";
import { FunnelPlotOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import Search from "antd/es/input/Search";
import { getFormattedActiveFilters } from "../../utils/formatting";
import UserBarGraph from "./UserBarGraph";
import { uuidRegex } from "../../utils/regex";
import { User, UserOutput } from "@thiana/api-thiana-client";

export interface UserDataSource extends User {
  key: string;
}

export default function Users() {
  const [usersDataSource, setUsersDataSource] = useState<UserDataSource[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [pagination, setPagination] = useState<PaginationList>({
    pages: undefined,
    total: undefined,
    currentPage: 1,
    items: 10,
  });
  const [searchUsersValue, setSearchUsersValue] = useState<string>("");
  const { activeUserFilters, apiThianaClient } = useContext(Context);
  const { getFilters_id } = useActiveUserFilters();

  // useEffect qui permet de récupérer les users (pour le graph)
  useEffect(() => {
    (async () => {
      let response = await apiThianaClient.apiUsers.userGetAllRaw({
        items: 99999,
        page: 1,
      });
      if (response.raw.status === 200) {
        let userOutput: UserOutput = await response.raw.json();
        setUsers(userOutput.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect qui permet de récupérer des users avec les critères de pagination (pour le tableau)
  useEffect(() => {
    if (activeUserFilters.length > 0 || searchUsersValue.length >= 3)
      fetchUsersWithFiltersAndPagination(searchUsersValue);
    else if (searchUsersValue.length === 0) fetchUsersWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, activeUserFilters.length, searchUsersValue]);

  const fetchUsersWithPagination = async () => {
    let response = await apiThianaClient.apiUsers.userGetAllRaw({
      items: pagination.items,
      page: pagination.currentPage,
    });

    if (response.raw.status === 200) {
      let userOutput: UserOutput = await response.raw.json();
      setPagination({
        ...pagination,
        total: userOutput.metadata?.total,
        pages: userOutput.metadata?.pages,
      });
      const mappedUsersData: UserDataSource[] = userOutput.data.map(
        (user: User) => ({
          ...user,
          key: user.id,
        })
      );
      setUsersDataSource(mappedUsersData);
    }
  };

  const fetchUsersWithFiltersAndPagination = async (searchValue?: string) => {
    let filters_id = getFilters_id();
    let response;

    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: 1,
    }));

    if (filters_id.length > 0 && searchValue)
      response = await apiThianaClient.apiUsers.userGetAllRaw({
        items: 10,
        page: pagination.currentPage,
        mode: "OR",
        like:
          "id:" +
          filters_id +
          "|last_name:" +
          searchValue +
          "|first_name:" +
          searchValue +
          "|email:" +
          searchValue,
      });
    else if (filters_id.length === 0 && searchValue) {
      if (uuidRegex.test(searchValue)) {
        response = await apiThianaClient.apiUsers.userGetAllRaw({
          items: 10,
          page: pagination.currentPage,
          equal: "id:" + searchValue,
        });
      } else {
        response = await apiThianaClient.apiUsers.userGetAllRaw({
          items: 10,
          page: pagination.currentPage,
          mode: "OR",
          like:
            "last_name:" +
            searchValue +
            "|first_name:" +
            searchValue +
            "|email:" +
            searchValue,
        });
      }
    } else if (filters_id.length > 0 && !searchValue)
      response = await apiThianaClient.apiUsers.userGetAllRaw({
        items: 10,
        page: pagination.currentPage,
        like: "id:" + filters_id,
      });
    else
      response = await apiThianaClient.apiUsers.userGetAllRaw({
        items: 10,
        page: pagination.currentPage,
      });
    if (response.raw.status === 200) {
      let userOutput: UserOutput = await response.raw.json();
      // On met à jour la pagination
      setPagination({
        ...pagination,
        total: userOutput.metadata?.total,
        pages: userOutput.metadata?.pages,
        currentPage: 1,
      });
      // On rajoute le key
      const mappedUsersData: UserDataSource[] = userOutput.data.map(
        (user: User) => ({
          ...user,
          key: user.id,
        })
      );
      // On met à jour le datasource
      setUsersDataSource(mappedUsersData);
    }
  };

  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Global",
            },
            {
              title: "Users",
            },
          ]}
        />
      </div>
      <div style={{ marginBottom: 30 }}>
        <UserBarGraph users={users} />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
            gap: 10,
          }}
        >
          <Search
            onFocus={() =>
              activeUserFilters.length > 0
                ? message.warning(
                    "Attention, " + getFormattedActiveFilters(activeUserFilters)
                  )
                : null
            }
            value={searchUsersValue}
            onChange={(e) => setSearchUsersValue(e.target.value)}
            placeholder="Rechercher des utilisateurs (first_name, last_name, email) ..."
            allowClear
          />
          <Button type="text" icon={<FunnelPlotOutlined />}></Button>
        </div>
        <UsersList
          usersDataSource={usersDataSource}
          setUsersDataSource={setUsersDataSource}
          pagination={pagination}
          setPagination={setPagination}
          nbTotalUsers={pagination.total}
        />
      </div>
    </div>
  );
}
