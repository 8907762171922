import { hasValidToken } from "../../utils/security";
import { useContext, useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import Context from "../../context/Context";
import {
  LogoutOutlined,
  StopOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import useLogout from "../../hooks/useLogout";
import "./Protected.css";
import { getFormattedEnvironment } from "../../utils/env";
import colors from "../../theme/thiana";

const FloatButtons = (props: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const { logout } = useLogout();
  const { env, updateEnv } = useContext(Context);

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      const mouseY = event.clientY;
      setIsVisible(mouseY < 50);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const changeEnvironnement = () => {
    switch (env) {
      case "development":
        updateEnv("preview");
        if (hasValidToken("preview")) window.location.reload();
        break;
      case "preview":
        updateEnv("production");
        if (hasValidToken("production")) window.location.reload();
        break;
      case "production":
        updateEnv("development");
        if (hasValidToken("development")) window.location.reload();
        break;
      default:
        break;
    }
  };

  const Bar = () => {
    return (
      <div
        style={{
          position: "fixed",
          height: 5,
          zIndex: 9999,
          backgroundColor:
            env === "development"
              ? colors.palette.success[700]
              : env === "preview"
              ? colors.palette.orange[700]
              : colors.palette.error[600],
          top: 0,
          right: 0,
          left: 0,
        }}
      ></div>
    );
  };

  // useEffect qui change le label de l'onglet du navigateur
  useEffect(() => {
    document.title = "Thiana Admin / " + getFormattedEnvironment(env);
  }, [env]);

  return (
    <div>
      <Bar />

      <div
        className={`bouton ${isVisible ? "visible" : ""}`}
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          zIndex: 9999,
          display: "flex",
          gap: 10,
        }}
      >
        <Tooltip title="Changer d'environnement" mouseEnterDelay={0.5}>
          <Button
            size="small"
            shape="circle"
            icon={<SwapRightOutlined />}
            style={{
              zIndex: 9999,
            }}
            onClick={changeEnvironnement}
          ></Button>
        </Tooltip>

        {hasValidToken("development") ? (
          <Tooltip title={"Déconnexion DEV"} mouseEnterDelay={0.5}>
            <Button
              size="small"
              shape="circle"
              icon={<LogoutOutlined />}
              style={{
                borderColor: colors.palette.success[700],
                color: colors.palette.success[700],
              }}
              onClick={() => logout("development")}
            ></Button>
          </Tooltip>
        ) : null}

        {hasValidToken("preview") ? (
          <Tooltip title={"Déconnexion PRÉPROD"} mouseEnterDelay={0.5}>
            <Button
              size="small"
              shape="circle"
              icon={<LogoutOutlined />}
              style={{
                borderColor: colors.palette.orange[800],
                color: colors.palette.orange[700],
              }}
              onClick={() => logout("preview")}
            ></Button>
          </Tooltip>
        ) : null}
        {hasValidToken("production") ? (
          <Tooltip title={"Déconnexion PROD"} mouseEnterDelay={0.5}>
            <Button
              size="small"
              shape="circle"
              icon={<LogoutOutlined />}
              style={{
                borderColor: colors.palette.error[600],
                color: colors.palette.error[600],
              }}
              onClick={() => logout("production")}
            ></Button>
          </Tooltip>
        ) : null}
        {hasValidToken("development") ||
        hasValidToken("preview") ||
        hasValidToken("production") ? (
          <Tooltip title="Déconnexion" mouseEnterDelay={0.5}>
            <Button
              shape="circle"
              size="small"
              icon={<StopOutlined />}
              style={{
                borderColor: colors.palette.error[600],
                color: colors.palette.error[600],
              }}
              onClick={() => logout("all")}
            ></Button>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default FloatButtons;
