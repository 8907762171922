import { useContext, useEffect, useState } from "react";
import Breadcrumb from "antd/es/breadcrumb";
import { Professional, ProfessionalsResponse } from "../../types/Professionals";
import { User, UsersResponse } from "../../types/Users";
import { Flex, message } from "antd";
import Search from "antd/es/input/Search";
import Context from "../../context/Context";
import useActiveUserFilters from "../../hooks/useActiveUserFilters";
import { EMPTY_UUID } from "../../utils/formatting";
import { getFormattedActiveFilters } from "../../utils/formatting";
import { ThianaPagination } from "../../types/Pagination";
import ProfessionalsList from "./ProfessionalsList";

export interface ProfessionalDataSource extends Professional {
  key: string;
  user?: User;
  fileAvatar?: string;
}

export default function Professionals() {
  const [searchValue, setSearchValue] = useState<string>("");
  const [professionalsDataSource, setProfessionalsDataSource] = useState<
    ProfessionalDataSource[]
  >([]);
  const { activeUserFilters, apiThianaClient } = useContext(Context);
  const { getFilters_professional_id } = useActiveUserFilters();

  const [professionalsPagination, setProfessionalsPagination] =
    useState<ThianaPagination>({
      items: 10,
      currentPage: 1,
      nbPages: undefined,
      totalItems: undefined,
    });

  // useEffect qui permet de récupérer des users avec les critères de pagination (pour le tableau)
  useEffect(() => {
    if (activeUserFilters.length > 0 || searchValue.length >= 3)
      fetchProfessionalsWithFiltersAndPagination(searchValue);
    else if (searchValue.length === 0) fetchProfessionals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    professionalsPagination.currentPage,
    activeUserFilters.length,
    searchValue,
  ]);

  const fetchProfessionals = async () => {
    // fetch 1 : On récupère les professionnals (avec pagination)
    let response1 = await apiThianaClient.apiProfessional.professionalGetAllRaw(
      {
        items: professionalsPagination.items,
        page: professionalsPagination.currentPage,
      }
    );
    if (response1?.raw.status === 200) {
      let professionalsResponse: ProfessionalsResponse;
      professionalsResponse = await response1.raw.json();
      // On met à jour la pagination
      if (professionalsResponse.metadata)
        setProfessionalsPagination({
          ...professionalsPagination,
          nbPages: professionalsResponse.metadata?.pages,
          totalItems: professionalsResponse.metadata?.total,
        });
      // On parcourt les professionnels pour remplir notre dataSource
      const tmpProfessionalsDataSourcePromises = professionalsResponse.data.map(
        async (professional: Professional) => {
          let usersResponse: UsersResponse;
          let dataSource: ProfessionalDataSource = {
            id: professional.id,
            key: professional.id,
            status: professional.status,
            created_at: professional.created_at,
            updated_at: professional.updated_at,
            delete_at: professional.delete_at,
            avatar: professional.avatar,
            speciality: professional.speciality,
            year_cycle: professional.year_cycle,
            user_id: professional.user_id,
            personal_workspace_id: professional.personal_workspace_id,
            phone: professional.phone,
          };
          // fetch 2 : On fetch le user associé à chaque professional
          let response2 = await apiThianaClient.apiUsers.userGetOneRaw({
            id: professional.user_id,
          });
          if (response2.raw.status === 200) {
            usersResponse = await response2.raw.json();
            dataSource = {
              ...dataSource,
              user: usersResponse.data[0],
            };
            // Et pour chaque, on fait une requete (async/await) dans le map, pour aller fetch l'avatar de chaque professionnel
            if (professional.avatar !== EMPTY_UUID) {
              let getFileResponse: any;
              let response3 = await apiThianaClient.apiStorages.getFileRaw({
                id: professional.avatar,
              });
              // fetch 3 : Si il y a un avatar, on l'ajoute au dataSource
              if (response3.raw.status === 200) {
                getFileResponse = await response3.raw.blob();
                dataSource = {
                  ...dataSource,
                  fileAvatar: URL.createObjectURL(getFileResponse),
                };
              }
            }
            return dataSource;
          }
        }
      );
      let tmpProfessionalsDataSource: any[] = await Promise.all(
        tmpProfessionalsDataSourcePromises
      );
      setProfessionalsDataSource(tmpProfessionalsDataSource);
    }
  };

  const fetchProfessionalsWithFiltersAndPagination = async (
    searchValue?: string
  ) => {
    let response1;
    let filters_id = getFilters_professional_id();
    // fetch 1 : On récupère les professionnals (avec pagination)
    if (filters_id.length > 0 && searchValue)
      response1 = await apiThianaClient.apiProfessional.professionalGetAllRaw({
        items: professionalsPagination.items,
        page: professionalsPagination.currentPage,
        equal: "equal=id:" + filters_id + "|id:" + searchValue,
      });
    else if (filters_id.length === 0 && searchValue)
      response1 = await apiThianaClient.apiProfessional.professionalGetAllRaw({
        items: professionalsPagination.items,
        page: professionalsPagination.currentPage,
        equal: "equal=id:" + searchValue,
      });
    else if (filters_id.length > 0 && !searchValue)
      response1 = await apiThianaClient.apiProfessional.professionalGetAllRaw({
        items: professionalsPagination.items,
        page: professionalsPagination.currentPage,
        equal: "equal=id:" + filters_id,
      });
    else
      response1 = await apiThianaClient.apiProfessional.professionalGetAllRaw({
        items: professionalsPagination.items,
        page: professionalsPagination.currentPage,
      });
    if (response1?.raw.status === 200) {
      let professionalsResponse: ProfessionalsResponse;
      professionalsResponse = await response1.raw.json();
      // On parcourt les professionnels pour remplir notre dataSource
      const tmpProfessionalsDataSourcePromises = professionalsResponse.data.map(
        async (professional: Professional) => {
          let usersResponse: UsersResponse;
          let dataSource: ProfessionalDataSource = {
            id: professional.id,
            key: professional.id,
            status: professional.status,
            created_at: professional.created_at,
            updated_at: professional.updated_at,
            delete_at: professional.delete_at,
            avatar: professional.avatar,
            speciality: professional.speciality,
            year_cycle: professional.year_cycle,
            user_id: professional.user_id,
            personal_workspace_id: professional.personal_workspace_id,
            phone: professional.phone,
          };
          // fetch 2 : On fetch le user associé à chaque professional
          let response2 = await apiThianaClient.apiUsers.userGetOneRaw({
            id: professional.user_id,
          });
          if (response2.raw.status === 200) {
            usersResponse = await response2.raw.json();
            dataSource = {
              ...dataSource,
              user: usersResponse.data[0],
            };
            // Et pour chaque, on fait une requete (async/await) dans le map, pour aller fetch l'avatar de chaque professionnel
            if (professional.avatar !== EMPTY_UUID) {
              let getFileResponse: any;
              let response3 = await apiThianaClient.apiStorages.getFileRaw({
                id: professional.avatar,
              });
              // fetch 3 : Si il y a un avatar, on l'ajoute au dataSource
              if (response3.raw.status === 200) {
                getFileResponse = await response3.raw.blob();
                dataSource = {
                  ...dataSource,
                  fileAvatar: URL.createObjectURL(getFileResponse),
                };
              }
            }
            return dataSource;
          }
        }
      );
      let tmpProfessionalsDataSource: any[] = await Promise.all(
        tmpProfessionalsDataSourcePromises
      );
      setProfessionalsDataSource(tmpProfessionalsDataSource);
    }
  };

  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Global",
            },
            {
              title: "Professionnels",
            },
          ]}
        />
      </div>
      <Flex vertical gap={"small"} style={{ marginTop: 8 }}>
        <Search
          onFocus={() =>
            activeUserFilters.length > 0
              ? message.warning(
                  "Attention, " + getFormattedActiveFilters(activeUserFilters)
                )
              : null
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Rechercher des professionnels par id..."
          allowClear
        />
        <ProfessionalsList
          professionalsDataSource={professionalsDataSource}
          setProfessionalsDataSource={setProfessionalsDataSource}
          professionalsPagination={professionalsPagination}
          setProfessionalsPagination={setProfessionalsPagination}
        />
      </Flex>
    </div>
  );
}
