import { Breadcrumb, Flex } from "antd";
import { useContext, useEffect, useState } from "react";
import { ThianaPagination } from "../../types/Pagination";
import Context from "../../context/Context";
import { Subscription, SubscriptionOutput } from "@thiana/api-thiana-client";
import SubscriptionsList, { SubscriptionDataSource } from "./SubscriptionsList";
import Search from "antd/es/input/Search";
import { SubscriptionPieChart } from "./SubscriptionPieGraph";
import SubscriptionDrawer from "./SubscriptionDrawer";

export default function Subscriptions() {
  const { apiThianaClient } = useContext(Context);
  const [searchValue, setSearchValue] = useState<string>("");
  const [totalSubscriptions, setTotalSubscriptions] = useState<Subscription[]>(
    []
  );
  const [subscriptionsDataSource, setSubscriptionsDataSource] = useState<
    SubscriptionDataSource[]
  >([]);
  const [subscriptionsPagination, setSubscriptionsPagination] =
    useState<ThianaPagination>({
      items: 10,
      currentPage: 1,
      nbPages: undefined,
      totalItems: undefined,
    });
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);

  // const fetchUserDetails = async (customerId: string) => {
  //   try {
  //     let response = await apiThianaClient.apiUsers.userGetOne({
  //       id: customerId,
  //     });
  //     if (response.raw.status === 200) {
  //       let user = await response.raw.json();
  //       return { firstName: user.first_name, lastName: user.last_name };
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch user details", error);
  //   }
  //   return { firstName: "Unknown", lastName: "Unknown" };
  // };

  const fetchSubscriptions = async () => {
    let response = await apiThianaClient.apiPayments.subscriptionGetAllRaw({
      items: subscriptionsPagination.items,
      page: subscriptionsPagination.currentPage,
    });
    if (response.raw.status === 200) {
      let subscriptions: any = await response.raw.json();
      setSubscriptionsPagination({
        ...subscriptionsPagination,
        totalItems: subscriptions.metadata?.total,
        nbPages: subscriptions.metadata?.pages,
      });

      const allReports = await fetchAllReports();
      const reportCounts = countReportsByUser(allReports);

      let tmp = await Promise.all(
        subscriptions.data.map(async (subscription: any, index: number) => {
          // let { firstName, lastName } = await fetchUserDetails(
          //   subscription.customer_id
          // );
          let reportCount = reportCounts[subscription.customer_id] || 0;
          return {
            ...subscription,
            key: index,
            // firstName,
            // lastName,
            reportCount,
          };
        })
      );
      setSubscriptionsDataSource(tmp);
    }
  };

  const fetchAllSubscriptions: any = async (
    page = 1,
    allSubscriptions: Subscription[] = []
  ) => {
    let response = await apiThianaClient.apiPayments.subscriptionGetAllRaw({
      items: 100,
      page: page,
    });

    if (response.raw.status === 200) {
      let subscriptions: any = await response.raw.json();
      allSubscriptions = allSubscriptions.concat(subscriptions.data);

      if (allSubscriptions.length < subscriptions.metadata?.total) {
        return fetchAllSubscriptions(page + 1, allSubscriptions);
      }
    }

    setTotalSubscriptions(allSubscriptions);
  };

  const fetchSubscriptionsByCustomerID = async () => {
    let filters = "customer_id:" + searchValue;
    let response = await apiThianaClient.apiPayments.subscriptionGetAllRaw({
      items: subscriptionsPagination.items,
      page: subscriptionsPagination.currentPage,
      equal: filters,
    });
    if (response.raw.status === 200) {
      let subscriptions: SubscriptionOutput = await response.raw.json();

      const allReports = await fetchAllReports();
      const reportCounts = countReportsByUser(allReports);

      let tmp = await Promise.all(
        subscriptions.data.map(
          async (subscription: Subscription, index: number) => {
            // let { firstName, lastName } = await fetchUserDetails(
            //   subscription.customer_id
            // );
            let reportCount = reportCounts[subscription.customer_id] || 0;
            return {
              ...subscription,
              key: subscription.id,
              // firstName,
              // lastName,
              reportCount,
            };
          }
        )
      );
      setSubscriptionsDataSource(tmp);
    }
  };

  useEffect(() => {
    fetchAllSubscriptions();
  }, []);

  useEffect(() => {
    if (searchValue.length >= 3) fetchSubscriptionsByCustomerID();
    else if (searchValue.length === 0) fetchSubscriptions();
  }, [subscriptionsPagination.currentPage, searchValue]);

  const fetchAllReports = async () => {
    let allReports: any = [];
    let page = 1;
    let totalPages = 1;

    while (totalPages && page <= totalPages) {
      let response = await apiThianaClient.apiReports.reportGetAllRaw({
        page,
        items: 100,
      });
      if (response.raw.status === 200) {
        let reports = await response.raw.json();
        allReports = allReports.concat(reports.data);
        totalPages = reports.metadata?.pages;
        page++;
      }
    }

    return allReports;
  };

  const countReportsByUser = (reports: any) => {
    const reportCounts: any = {};
    reports.forEach((report: any) => {
      const userId = report.customer_id;
      if (!reportCounts[userId]) {
        reportCounts[userId] = 0;
      }
      reportCounts[userId]++;
    });
    return reportCounts;
  };

  const onChangePaymentLevel = async (
    paymentLevel: number,
    subscription: Subscription
  ) => {
    let subscriptionOutput: SubscriptionOutput;
    let response = await apiThianaClient.apiPayments.subscriptionUpdateRaw({
      id: subscription.id,
      subscriptionPartialInput: {
        checked_until: subscription.checked_until,
        trial_until: subscription.trial_until,
        payment_level: paymentLevel,
        scope_id: subscription.scope_id,
        status: subscription.status,
      },
    });
    if (response.raw.status === 200) {
      subscriptionOutput = await response.raw.json();
      let tmp = [...subscriptionsDataSource];
      let index = tmp.findIndex(
        (subscriptionDataSource: SubscriptionDataSource) =>
          subscriptionDataSource.id === subscriptionOutput.data[0].id
      );
      tmp[index] = {
        ...subscriptionOutput.data[0],
        key: subscriptionOutput.data[0].id,
      };
      setSubscriptionsDataSource(tmp);
    }
  };

  const convertStringToNumber = (paymentLevel: string) => {
    switch (paymentLevel) {
      case "assistant unpaid":
        return 1;
      case "assistant essential":
        return 3;
      case "assistant integral":
        return 2;
      default:
        return 0;
    }
  };

  const onChangeStatus = async (status: string, subscription: Subscription) => {
    let subscriptionOutput: SubscriptionOutput;
    let response = await apiThianaClient.apiPayments.subscriptionUpdateRaw({
      id: subscription.id,
      subscriptionPartialInput: {
        checked_until: subscription.checked_until,
        trial_until: subscription.trial_until,
        payment_level: convertStringToNumber(subscription.payment_level),
        scope_id: subscription.scope_id,
        status: status,
      },
    });
    if (response.raw.status === 200) {
      subscriptionOutput = await response.raw.json();
      let tmp = [...subscriptionsDataSource];
      let index = tmp.findIndex(
        (subscriptionDataSource: SubscriptionDataSource) =>
          subscriptionDataSource.id === subscriptionOutput.data[0].id
      );
      tmp[index] = {
        ...subscriptionOutput.data[0],
        key: subscriptionOutput.data[0].id,
      };
      setSubscriptionsDataSource(tmp);
    }
  };

  const showDrawer = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    setSelectedSubscription(null);
  };

  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Global",
            },
            {
              title: "Subscriptions",
            },
          ]}
        />
      </div>
      <SubscriptionPieChart subscriptions={totalSubscriptions} />

      <Flex vertical gap={"small"} style={{ marginTop: 10 }}>
        <Search
          placeholder="Rechercher des subscriptions par customer_id ..."
          allowClear
          value={searchValue}
          onChange={(e: any) => setSearchValue(e.target.value)}
        />
        <SubscriptionsList
          subscriptionsDataSource={subscriptionsDataSource}
          setSubscriptionsDataSource={setSubscriptionsDataSource}
          subscriptionsPagination={subscriptionsPagination}
          setSubscriptionsPagination={setSubscriptionsPagination}
          fetchSubscriptions={fetchSubscriptions}
          fetchSubscriptionsByCustomerID={fetchSubscriptionsByCustomerID}
          onChangePaymentLevel={onChangePaymentLevel}
          onChangeStatus={onChangeStatus}
          showDrawer={showDrawer}
        />
      </Flex>
      <SubscriptionDrawer
        visible={drawerVisible}
        onClose={onCloseDrawer}
        subscription={selectedSubscription}
      />
    </div>
  );
}
