import {
  Button,
  Modal,
  Pagination,
  Space,
  Table,
  Segmented,
  Collapse,
  Rate,
  CollapseProps,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { WrapperDataSource } from "./Reports";
import { SegmentedValue } from "antd/es/segmented";
import { useNavigate, useParams } from "react-router-dom";
import { Report, ReportsResponse } from "../../types/Reports";
import { Snapshot, SnapshotsResponse } from "../../types/Snapshot";
import colors from "../../theme/thiana";
import { Professional } from "../../types/Professionals";
import { User } from "../../types/Users";
import Context from "../../context/Context";
import { ThianaPagination } from "../../types/Pagination";

interface Props {
  wrappersDataSource: WrapperDataSource[];
  pagination: ThianaPagination;
  setPagination: React.Dispatch<React.SetStateAction<ThianaPagination>>;
}

export default function ReportList(props: Props) {
  const [selectedReport, setSelectedReport] = useState<Report>();
  const [activeTab, setActiveTab] = useState<string>("report");
  let { id } = useParams();
  let navigate = useNavigate();
  const [items, setItems] = useState<CollapseProps["items"]>([]);
  const { apiThianaClient } = useContext(Context);
  let { environment } = useParams();

  const onClickOpenModal = async (report: Report) => {
    navigate("/" + environment + "/reports/" + report.id);
  };

  const handleModalCancel = () => {
    setActiveTab("report");
    navigate("/" + environment + "/reports");
    setItems([]);
  };

  useEffect(() => {
    if (id)
      (async () => {
        let response = await apiThianaClient.apiReports.reportGetRaw({
          id: id,
        });
        if (response.raw.status === 200) {
          let reportReponse: ReportsResponse = await response.raw.json();
          setSelectedReport(reportReponse.data[0]);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let response =
          await apiThianaClient.apiReportSnapshots.reportSnapshotGetAllRaw({
            equal: "id:" + id,
          });
        if (response.raw.status === 200) {
          let snapshotResponse: SnapshotsResponse = await response.raw.json();
          if (snapshotResponse.data !== null) {
            // On met à jour les items du <Collapse> avec le retour de la requete reportSnapshotGetAllRaw()
            setItems(
              snapshotResponse.data.map((snapshot: Snapshot, index: number) => {
                return {
                  key: index,
                  label: (
                    <div
                      style={{
                        display: "flex",
                        gap: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{snapshot.id}</div>
                      <div>{snapshot.created_at}</div>
                    </div>
                  ),
                  children: (
                    <div
                      style={{
                        maxHeight: "300px",
                        display: "flex",
                        gap: 20,
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>Transcription</p>
                        </div>
                        <div
                          style={{
                            height: "85%",
                            maxHeight: "85%",
                            overflowY: "auto",
                            textAlign: "justify",
                            paddingRight: "10px",
                          }}
                        >
                          {createParagraphs(snapshot.transcription)}
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>Génération</p>
                        </div>
                        <div
                          style={{
                            height: "85%",
                            maxHeight: "85%",
                            overflowY: "auto",
                            textAlign: "justify",
                            paddingRight: "10px",
                          }}
                        >
                          {createParagraphs(snapshot.generation)}
                        </div>
                      </div>
                    </div>
                  ),
                };
              })
            );
          }
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Fonction qui permet d'afficher le texte en prenant en compte les retours à la ligne
  const createParagraphs = (text: string | undefined) => {
    if (!text) return null;
    const lines = text.split("\n");
    return (
      <>
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </>
    );
  };

  // Fonction qui retourne le rendu de la <Modal/>
  const renderModalContent = () => {
    return (
      <div style={{ height: 500, maxHeight: 500 }}>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Segmented
            defaultValue="report"
            value={activeTab}
            options={[
              { label: "Report", value: "report" },
              { label: "Feedbacks", value: "feedbacks" },
              { label: "Snapshots", value: "snapshots" },
            ]}
            onChange={(value: SegmentedValue) => setActiveTab(value as string)}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 50,
            height: "5%",
            maxHeight: "5%",
            paddingBottom: 10,
            borderBottom: "1px solid #434343",
            marginTop: "10px",
          }}
        >
          <p style={{ fontSize: 12, margin: 0 }}>
            crée le : {selectedReport?.created_at.split(" ")[0]} à
            {selectedReport?.created_at.split(" ")[1]}
          </p>
          <p style={{ fontSize: 12, margin: 0 }}>
            mis à jour le : {selectedReport?.updated_at.split(" ")[0]} à
            {selectedReport?.created_at.split(" ")[1]}
          </p>
        </div>
        {activeTab === "report" && (
          <div
            style={{
              height: "85%",
              maxHeight: "90%",
              display: "flex",
              // overflowY: "auto",
              paddingRight: "10px",
              gap: 20,
            }}
          >
            <div style={{ flex: 1, height: "90%", maxHeight: "90%" }}>
              <div>
                <p style={{ fontWeight: "bold" }}>Transcription</p>
              </div>
              <div
                style={{
                  height: "94%",
                  maxHeight: "94%",
                  overflowY: "auto",
                  textAlign: "justify",
                  paddingRight: "10px",
                }}
              >
                {createParagraphs(selectedReport?.transcription)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                height: "90%",
                maxHeight: "90%",
              }}
            >
              <div>
                <p style={{ fontWeight: "bold" }}>Génération</p>
              </div>
              <div
                style={{
                  height: "94%",
                  maxHeight: "94%",
                  overflowY: "auto",
                  textAlign: "justify",
                }}
              >
                {createParagraphs(selectedReport?.generation)}
              </div>
            </div>
          </div>
        )}
        {activeTab === "feedbacks" && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            {selectedReport?.feedbacks &&
              selectedReport?.feedbacks
                .map((feedback, index) => (
                  <div key={index} style={{ marginBottom: 30 }}>
                    <Rate value={feedback.rate} disabled />
                    <p style={{ margin: 0, fontSize: 12 }}>
                      {feedback.created_at}
                    </p>
                    <p style={{ margin: 0, fontSize: 16 }}>
                      {feedback.comment}
                    </p>
                  </div>
                ))
                .reverse()}
          </div>
        )}
        {activeTab === "snapshots" && (
          <div
            style={{
              overflowY: "auto",
              height: "85%",
              maxHeight: "85%",
              marginTop: 10,
            }}
          >
            <Collapse accordion items={items} />
          </div>
        )}
      </div>
    );
  };
  const columns: ColumnsType<WrapperDataSource> = [
    {
      title: <div style={{ fontSize: 12 }}>Nom/Prénom</div>,
      dataIndex: "user",
      key: "user",
      render: (user: User) => {
        if (user.id)
          return (
            <p>
              {user.first_name} {user.last_name}
            </p>
          );
        else return null;
      },
    },
    {
      title: <code style={{ fontSize: 12 }}>professional_id</code>,
      dataIndex: "professional",
      key: "professional_id",
      render: (professional: Professional) => {
        if (professional.id) return <p>{professional.id}</p>;
        else return null;
      },
    },
    {
      title: <code style={{ fontSize: 12 }}>user_id</code>,
      dataIndex: "user",
      key: "user_id",
      render: (user: User) => {
        if (user.id) return <p>{user.id}</p>;
        else return null;
      },
    },
    {
      title: <code style={{ fontSize: 12 }}>title</code>,
      dataIndex: "report",
      key: "title",
      render: (report: Report) => <p>{report.title}</p>,
    },
    {
      title: <code style={{ fontSize: 12 }}>created_at</code>,
      dataIndex: "report",
      key: "created_at",
      render: (report: Report) => <p>{report.created_at}</p>,

      // sorter: (a, b) =>
      //   convertDateIntoTimestamp(a.report?.created_at) -
      //   convertDateIntoTimestamp(b.created_at),
    },
    {
      title: <code style={{ fontSize: 12 }}>updated_at</code>,
      dataIndex: "report",
      key: "updated_at",
      render: (report: Report) => <p>{report.updated_at}</p>,
      // sorter: (a, b) =>
      //   convertDateIntoTimestamp(a.created_at) -
      //   convertDateIntoTimestamp(b.created_at),
    },
    {
      title: "Nombre de feedbacks",
      dataIndex: "report",
      key: "feedbacks",
      render: (report: Report) => <p>{report.feedbacks?.length}</p>,
    },
    {
      title: "Actions",
      dataIndex: "report",
      key: "actions",
      render: (report: Report) => (
        <Space size="middle">
          <Button type="link" onClick={() => onClickOpenModal(report)}>
            Voir
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Modal
        width={"90%"}
        title={selectedReport?.title ? selectedReport?.title : "Sans titre"}
        open={id ? true : false}
        onCancel={handleModalCancel}
        footer={null}
      >
        {renderModalContent()}
      </Modal>

      <div
        style={{
          border: "1px solid rgba(128, 128, 128, 0.5)",
          borderRadius: "5px",
        }}
      >
        <Table
          columns={columns}
          dataSource={props.wrappersDataSource}
          pagination={false}
        />

        <Pagination
          style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
          defaultCurrent={props.pagination.currentPage}
          current={props.pagination.currentPage}
          total={props.pagination.totalItems}
          onChange={(newPage) =>
            props.setPagination({ ...props.pagination, currentPage: newPage })
          }
          showSizeChanger={false}
          showTotal={(total) => (
            <span style={{ fontSize: 12, color: colors.palette.grey[600] }}>
              {total} reports au total
            </span>
          )}
        />
      </div>
    </div>
  );
}
