import { useContext, useEffect, useState } from "react";
import ReportList from "./ReportList";
import { Report, ReportsResponse } from "../../types/Reports";
import { Breadcrumb, Button } from "antd";
import Context from "../../context/Context";
import { FunnelPlotOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { User, UsersResponse } from "../../types/Users";
import { Professional, ProfessionalsResponse } from "../../types/Professionals";
import BarGraph from "./BarGraph";
import { ThianaPagination } from "../../types/Pagination";
import { uuidRegex } from "../../utils/regex";

export type WrapperDataSource = {
  key: string;
  report: Report;
  user: User;
  professional: Professional;
};

export default function Reports() {
  const [wrappersDataSource, setWrappersDataSource] = useState<
    WrapperDataSource[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [reports, setReports] = useState<Report[]>([]);
  const [pagination, setPagination] = useState<ThianaPagination>({
    nbPages: undefined,
    totalItems: undefined,
    currentPage: 1,
    items: 5,
  });
  const { activeUserFilters, apiThianaClient } = useContext(Context);

  // useEffect qui permet de récupérer des reports avec les critères de pagination
  useEffect(() => {
    (async () => {
      let reportsResponse: ReportsResponse;
      let response = await apiThianaClient.apiReports.reportGetAllRaw({
        items: 99999,
        page: 1,
      });
      if (response.raw.status === 200) {
        reportsResponse = await response.raw.json();
        if (reportsResponse.data !== null) setReports(reportsResponse.data);
        else setReports([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchValue) fetchReports(searchValue);
    else fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, activeUserFilters, searchValue]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPagination({ ...pagination, currentPage: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPagination({ ...pagination, currentPage: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const fetchReports = async (searchValue?: string) => {
    try {
      // Les trois entités dont on a besoin pour remplir le tableau des "reports"
      let allPro: Professional[] = [];
      let allUsers: User[] = [];
      let allReports: Report[] = [];

      let reportsResponse: ReportsResponse;
      let param =
        searchValue && uuidRegex.test(searchValue)
          ? {
              items: pagination.items,
              page: pagination.currentPage,
              equal: `equal=professional_id:${searchValue}`,
            }
          : {
              items: pagination.items,
              page: pagination.currentPage,
            };
      let response = await apiThianaClient.apiReports.reportGetAllRaw(param);

      // On récupére les professionnels  contenu dans les reports dans un tableau
      let professionals: string[] = [];
      if (response.raw.status === 200) {
        reportsResponse = await response.raw.json();
        allReports = reportsResponse.data;
        if (reportsResponse.metadata)
          setPagination({
            ...pagination,
            totalItems: reportsResponse.metadata?.total,
            nbPages: reportsResponse.metadata?.pages,
          });
        if (reportsResponse.data !== null) {
          reportsResponse.data.forEach((report: Report) => {
            professionals.push(report.professional_id);
          });
        }
        // Puis on supprime les id en doublons
        let uniqueUsers: string[] = [];
        let uniqueProfessionals = Array.from(new Set(professionals));
        // Enfin, on concatène les string du tableau en les séparant par des virgules et en ajoutant "id:" devant
        let filtersPro = `equal=id:${uniqueProfessionals.join(",")}`;

        // On fetch les professionnels avec le apiThianaClient.apiProfessional. apiThianaClient.apiProfessional.professionalGetAllRaw
        let professionalsResponse: ProfessionalsResponse;
        let responsePro =
          await apiThianaClient.apiProfessional.professionalGetAllRaw({
            equal: filtersPro,
          });
        if (responsePro.raw.status === 200) {
          professionalsResponse = await responsePro.raw.json();
          allPro = professionalsResponse.data;
          // On prend tous les user_id du professionalsResponse.data
          uniqueUsers = professionalsResponse.data.map(
            (pro: Professional) => pro.user_id
          );
          // On fetch tous les users
          let usersResponse: UsersResponse;
          let responseUsers = await apiThianaClient.apiUsers.userGetAllRaw({
            equal: `id:${uniqueUsers.join(",")}`,
          });
          if (responseUsers.raw.status === 200) {
            usersResponse = await responseUsers.raw.json();
            allUsers = usersResponse.data;
          }
        }
      }

      // On utilise tous les éléments récupérés pour remplir le tableau des reports
      let tmpWrappersDataSource: WrapperDataSource[] = [];
      allReports.forEach((report: Report) => {
        let user = allUsers.find(
          (user) => user.professional_id === report.professional_id
        );
        let professional = allPro.find(
          (pro) => pro.id === report.professional_id
        );
        if (user && professional) {
          let wrapperDataSource: WrapperDataSource = {
            key: report.id,
            user: user,
            professional: professional,
            report: report,
          };
          tmpWrappersDataSource.push(wrapperDataSource);
        }
      });
      setWrappersDataSource(tmpWrappersDataSource);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Thiana Assistant",
            },
            {
              title: "Reports",
            },
          ]}
        />
      </div>
      <div style={{ marginBottom: 30 }}>
        <BarGraph reports={reports} />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
            gap: 10,
          }}
        >
          <Search
            placeholder="Rechercher des reports par professional_id ..."
            allowClear
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button type="text" icon={<FunnelPlotOutlined />}></Button>
        </div>
        <ReportList
          wrappersDataSource={wrappersDataSource}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </div>
  );
}
