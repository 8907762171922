import { Avatar, Button, Pagination } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { EllipsisOutlined } from "@ant-design/icons";
import { useState } from "react";
import DrawerListProfessional from "./DrawerUsers/DrawerListProfessional";
import { ProfessionalDataSource } from "./Professionals";
import { User } from "../../types/Users";
import { ThianaPagination } from "../../types/Pagination";

interface Props {
  professionalsDataSource: ProfessionalDataSource[];
  setProfessionalsDataSource: React.Dispatch<
    React.SetStateAction<ProfessionalDataSource[]>
  >;
  professionalsPagination: ThianaPagination;
  setProfessionalsPagination: React.Dispatch<
    React.SetStateAction<ThianaPagination>
  >;
}

export default function ProfessionalsList(props: Props) {
  const [open, setOpen] = useState(false);
  const [selectedProfessional, setSelectedProfessional] =
    useState<ProfessionalDataSource | null>(null);

  const showDrawer = (professional: ProfessionalDataSource) => {
    setOpen(true);
    setSelectedProfessional(professional);
  };

  const columns: ColumnsType<ProfessionalDataSource> = [
    {
      title: <span style={{ fontSize: 10 }}>email</span>,
      dataIndex: "user",
      key: "email",
      width: 150,
      fixed: "left",
      render: (user: User) => <div style={{ fontSize: 10 }}>{user.email}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>id</code>,
      key: "id",
      fixed: "left",
      width: 150,
      render: (professionalDataSource: ProfessionalDataSource) => (
        <div style={{ fontSize: 10 }}>{professionalDataSource.id}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>phone</code>,
      key: "phone",
      dataIndex: "phone",
      width: 120,
      render: (phone: string) => <div style={{ fontSize: 10 }}>{phone}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>avatar</code>,
      key: "avatar",
      width: 80,
      render: (professionalDataSource: ProfessionalDataSource) => (
        <Avatar src={professionalDataSource.fileAvatar} />
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>status</code>,
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (status: string) => <div style={{ fontSize: 10 }}>{status}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>speciality</code>,
      dataIndex: "speciality",
      key: "speciality",
      render: (speciality: string) => (
        <div style={{ fontSize: 10 }}>{speciality}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>year_cycle</code>,
      dataIndex: "year_cycle",
      key: "year_cycle",
      render: (year_cycle: number) => (
        <div style={{ fontSize: 10 }}>
          {year_cycle !== 0 ? year_cycle : null}
        </div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>personal_workspace_id</code>,
      dataIndex: "id",
      key: "personal_workspace_id",
      width: 200,
      render: (id: string) => <div style={{ fontSize: 10 }}>{id}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>rpps</code>,
      dataIndex: "rpps",
      key: "rpps",
      width: 80,
      render: (rpps: number) => <div style={{ fontSize: 10 }}>{rpps}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>voice_usage_accepted_at</code>,
      dataIndex: "voice_usage_accepted_at",
      key: "voice_usage_accepted_at",
      render: (voice_usage_accepted_at: string) => (
        <div style={{ fontSize: 10 }}>{voice_usage_accepted_at}</div>
      ),
    },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    // render: (_, { tags }) => (
    //   <>
    //     {tags.map((tag) => {
    //       let color = tag.length > 5 ? "geekblue" : "green";
    //       if (tag === "Généraliste") {
    //         color = "volcano";
    //       }
    //       return (
    //         <Tag color={color} key={tag}>
    //           {tag.toUpperCase()}
    //         </Tag>
    //       );
    //     })}
    //   </>
    // ),
    // },
    {
      title: <span style={{ fontSize: 10 }}>actions</span>,
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => showDrawer(record)}
          icon={<EllipsisOutlined />}
        />
      ),
      width: 80,
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          border: "1px solid rgba(128, 128, 128, 0.5)",
          borderRadius: "5px",
        }}
      >
        <Table
          columns={columns}
          dataSource={props.professionalsDataSource}
          scroll={{ x: 1300 }}
          pagination={false}
        />
        <Pagination
          style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
          defaultCurrent={props.professionalsPagination.currentPage}
          current={props.professionalsPagination.currentPage}
          total={props.professionalsPagination.totalItems}
          onChange={(newPage) =>
            props.setProfessionalsPagination({
              ...props.professionalsPagination,
              currentPage: newPage,
            })
          }
          showSizeChanger={false}
          showTotal={(total) => (
            <span style={{ fontSize: 12, color: "grey" }}>
              {total} professionnels au total
            </span>
          )}
        />
      </div>
      <DrawerListProfessional
        open={open}
        setOpen={setOpen}
        professionalsDataSource={props.professionalsDataSource}
        setProfessionalsDataSource={props.setProfessionalsDataSource}
        selectedProfessional={selectedProfessional}
        setSelectedProfessional={setSelectedProfessional}
      />
    </div>
  );
}
