import { hasValidToken } from "../../utils/security";
import Modal from "antd/es/modal/Modal";
import { useContext, useEffect, useState } from "react";
import useLogin from "../../hooks/useLogin";
import { Button, Input } from "antd";
import Context from "../../context/Context";
import { CheckOutlined } from "@ant-design/icons";
import "./Protected.css";
import colors from "../../theme/thiana";
import { useNavigate } from "react-router-dom";

const itemStyle: React.CSSProperties = {
  cursor: "pointer",
  textAlign: "center",
  flex: 1,
  padding: 5,
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: "solid",
};

const Protected = (props: any) => {
  const { env, updateEnv } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let { login } = useLogin();
  let navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("env", env);

    if (!hasValidToken(env)) setIsModalOpen(true);
    if (env) navigate("/" + env);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  const clickLogin = async () => {
    let result = await login(email, password);
    if (result) setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {isModalOpen ? (
        <Modal open={isModalOpen} onCancel={handleCancel} footer={false}>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              textAlign: "center",
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            <div
              className={env !== "development" ? "item" : "noselect"}
              onClick={() => {
                updateEnv("development");
                if (hasValidToken("development")) window.location.reload();
              }}
              style={{
                ...itemStyle,
                borderColor:
                  env === "development"
                    ? colors.palette.success[700]
                    : "transparent",
              }}
            >
              <div>Développement</div>

              <div>
                <code
                  style={{
                    fontSize: 10,
                    color: hasValidToken("development")
                      ? colors.palette.success[700]
                      : colors.palette.grey[500],
                  }}
                >
                  {hasValidToken("development") ? "connecté" : "non connecté"}
                </code>
                <CheckOutlined
                  style={{
                    color: hasValidToken("development")
                      ? colors.palette.success[700]
                      : colors.palette.grey[500],
                    marginLeft: 5,
                  }}
                />
              </div>
            </div>
            <div
              className={env !== "preview" ? "item" : "noselect"}
              onClick={() => {
                updateEnv("preview");
                if (hasValidToken("preview")) window.location.reload();
              }}
              style={{
                ...itemStyle,
                borderColor:
                  env === "preview"
                    ? colors.palette.orange[500]
                    : "transparent",
              }}
            >
              Préproduction
              <div>
                <code
                  style={{
                    fontSize: 10,
                    color: hasValidToken("preview")
                      ? colors.palette.orange[500]
                      : colors.palette.grey[500],
                  }}
                >
                  {hasValidToken("preview") ? "connecté" : "non connecté"}
                </code>
                <CheckOutlined
                  style={{
                    color: hasValidToken("preview")
                      ? colors.palette.orange[500]
                      : colors.palette.grey[500],
                    marginLeft: 5,
                  }}
                />
              </div>
            </div>
            <div
              className={env !== "production" ? "item" : "noselect"}
              onClick={() => {
                updateEnv("production");
                if (hasValidToken("production")) window.location.reload();
              }}
              style={{
                ...itemStyle,
                borderColor:
                  env === "production"
                    ? colors.palette.danger[600]
                    : "transparent",
              }}
            >
              Production
              <div>
                <code
                  style={{
                    fontSize: 10,
                    color: hasValidToken("production")
                      ? colors.palette.danger[600]
                      : colors.palette.grey[500],
                  }}
                >
                  {hasValidToken("production") ? "connecté" : "non connecté"}
                </code>
                <CheckOutlined
                  style={{
                    color: hasValidToken("production")
                      ? colors.palette.success[700]
                      : colors.palette.grey[500],
                    marginLeft: 5,
                  }}
                />
              </div>
            </div>
            <div
              className={env !== "audit" ? "item" : "noselect"}
              onClick={() => {
                updateEnv("audit");
                if (hasValidToken("audit")) window.location.reload();
              }}
              style={{
                ...itemStyle,
                borderColor:
                  env === "audit" ? colors.palette.danger[600] : "transparent",
              }}
            >
              Audit
              <div>
                <code
                  style={{
                    fontSize: 10,
                    color: hasValidToken("audit")
                      ? colors.palette.danger[600]
                      : colors.palette.grey[500],
                  }}
                >
                  {hasValidToken("audit") ? "connecté" : "non connecté"}
                </code>
                <CheckOutlined
                  style={{
                    color: hasValidToken("audit")
                      ? colors.palette.success[700]
                      : colors.palette.grey[500],
                    marginLeft: 5,
                  }}
                />
              </div>
            </div>
          </div>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            placeholder="Adresse email"
            name="email"
            autoFocus
            style={{
              marginTop: 5,
              marginBottom: 5,
              width: "100%",
            }}
          />
          <Input.Password
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
            type="password"
            style={{
              marginTop: 5,
              marginBottom: 5,
              width: "100%",
            }}
          />
          <Button
            onClick={clickLogin}
            type="primary"
            style={{
              width: "100%",
              marginTop: 15,
              backgroundColor:
                env === "development"
                  ? colors.palette.success[700]
                  : env === "preview"
                  ? colors.palette.orange[500]
                  : colors.palette.danger[600],
            }}
          >
            Connexion
          </Button>
        </Modal>
      ) : hasValidToken(env) ? (
        props.children
      ) : null}
    </div>
  );
};
export default Protected;
