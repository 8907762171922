import React, { useContext, useEffect } from "react";
import AIContext from "../context/AIContext";

export default function useJSONForAI() {
  const {
    title,
    inputText,
    blocks,
    variables,
    outputText,
    frequencyPenality,
    presencePenality,
    temperature,
  } = useContext(AIContext);

  const downloadJSONState = () => {
    const data = {
      title,
      inputText,
      blocks,
      variables,
      outputText,
      frequencyPenality,
      presencePenality,
      temperature,
    };
    console.log("Data:", data); // Log data before stringify
    const jsonData = JSON.stringify(data);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = title + ".json";
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return { downloadJSONState };
}
