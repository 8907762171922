import { Breadcrumb, Flex, Pagination } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { ThianaPagination } from "../../types/Pagination";
import Search from "antd/es/input/Search";
import Context from "../../context/Context";

export interface CustomerDataSource extends Customer {
  key: string;
}

type Customer = {
  id: string;
  professional_id: string;
  created_at: string;
  customer_external_id: string;
  updated_at: string;
};

export default function Patients() {
  const [searchValue, setSearchValue] = useState<string>("");
  const [patientsDataSource, setPatientsDataSource] = useState<
    CustomerDataSource[]
  >([]);
  const [patientsPagination, setPatientsPagination] =
    useState<ThianaPagination>({
      items: 10,
      currentPage: 1,
      nbPages: undefined,
      totalItems: undefined,
    });

  const { apiThianaClient } = useContext(Context);

  const fetchPatients = async () => {
    let response = await apiThianaClient.apiPatients.patientGetAllRaw({
      items: patientsPagination.items,
      page: patientsPagination.currentPage,
    });
    if (response.raw.status === 200) {
      let patients: any = await response.raw.json();
      if (patients.metadata)
        setPatientsPagination({
          ...patientsPagination,
          nbPages: patients.metadata?.pages,
          totalItems: patients.metadata?.total,
        });
      let tmp = patients.data.map((customer: any, index: number) => {
        return { ...customer, key: index };
      });
      setPatientsDataSource(tmp);
    }
  };

  const fetchPatientsByProfessionalID = async () => {
    let filters = "professional_id:" + searchValue;
    let response = await apiThianaClient.apiPatients.patientGetAllRaw({
      items: patientsPagination.items,
      page: patientsPagination.currentPage,
      equal: filters,
    });
    if (response.raw.status === 200) {
      let customers: any = await response.raw.json();
      let tmp = customers.data.map((customer: any, index: number) => {
        return { ...customer, key: index };
      });
      setPatientsDataSource(tmp);
    }
  };

  // useEffect qui permet de récupérer les reports (avec ou sans filtre)
  useEffect(() => {
    if (searchValue.length >= 3) fetchPatientsByProfessionalID();
    else if (searchValue.length === 0) fetchPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientsPagination.currentPage, searchValue]);

  const columns: ColumnsType<CustomerDataSource> = [
    {
      title: <code style={{ fontSize: 10 }}>id</code>,
      dataIndex: "id",
      key: "id",

      render: (id: string) => <div style={{ fontSize: 10 }}>{id}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>user_id</code>,
      dataIndex: "user_id",
      key: "user_id",

      render: (user_id: string) => (
        <div style={{ fontSize: 10 }}>{user_id}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>ssn</code>,
      dataIndex: "ssn",
      key: "ssn",

      render: (ssn: string) => <div style={{ fontSize: 10 }}>{ssn}</div>,
    },
    {
      title: <code style={{ fontSize: 10 }}>created_at</code>,
      dataIndex: "created_at",
      key: "created_at",

      render: (created_at: string) => (
        <div style={{ fontSize: 10 }}>{created_at}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>updated_at</code>,
      dataIndex: "updated_at",
      key: "updated_at",

      render: (updated_at: string) => (
        <div style={{ fontSize: 10 }}>{updated_at}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>deleted_at</code>,
      dataIndex: "deleted_at",
      key: "deleted_at",

      render: (deleted_at: string) => (
        <div style={{ fontSize: 10 }}>{deleted_at}</div>
      ),
    },
  ];

  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Global",
            },
            {
              title: "Patients",
            },
          ]}
        />
      </div>
      <Flex vertical gap={"small"} style={{ marginTop: 10 }}>
        <Search
          placeholder="Rechercher des customers par professional_id ..."
          allowClear
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div
          style={{
            border: "1px solid rgba(128, 128, 128, 0.5)",
            borderRadius: "5px",
          }}
        >
          <Table
            columns={columns}
            dataSource={patientsDataSource}
            pagination={false}
          />
          <Pagination
            style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
            defaultCurrent={patientsPagination.currentPage}
            current={patientsPagination.currentPage}
            total={patientsPagination.totalItems}
            onChange={(newPage) =>
              setPatientsPagination({
                ...patientsPagination,
                currentPage: newPage,
              })
            }
            showSizeChanger={false}
            showTotal={(total) => (
              <span style={{ fontSize: 12, color: "grey" }}>
                {total} patients au total
              </span>
            )}
          />
        </div>
      </Flex>
    </div>
  );
}
