import { createContext } from "react";
import { ActiveUserFilter } from "../types/Users";
import { ApiThianaClient } from "../api/api-thiana-client/useApiThianaClient";
import {
  AuthApi,
  UsersApi,
  ReferentialsApi,
  StoragesApi,
  ProfessionalApi,
  ReportsApi,
  ReportSnapshotsApi,
  ProfessionalsApi,
  PaymentsApi,
  PatientsApi,
} from "@thiana/api-thiana-client";

// Define context type
type Context = {
  env: "development" | "preview" | "production" | "audit";
  updateEnv: (arg: "development" | "preview" | "production" | "audit") => void;

  activeUserFilters: ActiveUserFilter[];
  updateActiveUserFilters: (arg: ActiveUserFilter[]) => void;

  apiThianaClient: ApiThianaClient;
};

// Context declaration
export default createContext<Context>({
  env: "development",
  updateEnv: (arg: "development" | "preview" | "production" | "audit") => {},

  activeUserFilters: [],
  updateActiveUserFilters: (arg: ActiveUserFilter[]) => {},

  apiThianaClient: {
    apiAuth: new AuthApi(),
    apiUsers: new UsersApi(),
    apiStorages: new StoragesApi(),
    apiReports: new ReportsApi(),
    apiReportSnapshots: new ReportSnapshotsApi(),
    apiProfessional: new ProfessionalApi(),
    apiProfessionals: new ProfessionalsApi(),
    apiReferentials: new ReferentialsApi(),
    apiPatients: new PatientsApi(),
    apiPayments: new PaymentsApi(),
  },
});
