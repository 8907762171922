import { Input } from "antd";
import { useContext } from "react";
import AIContext from "../../../../context/AIContext";

export default function Output() {
  const { outputText, updateOutputText } = useContext(AIContext);

  return (
    <div
      style={{
        borderRadius: "8px",
        padding: "8px",
        backgroundColor: "#141414",
        // border: "1px solid #545454",
      }}
    >
      Output
      <Input.TextArea
        value={outputText}
        onChange={(e) => updateOutputText(e.target.value)}
      />
    </div>
  );
}
