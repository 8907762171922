import { Input } from "antd";
import { useContext } from "react";
import AIContext from "../../../../../context/AIContext";
import { VariableType } from "../../../../../types/AITypes";

interface Props {
  id: string;
  name: string;
  value: string;
}

export default function Variable(props: Props) {
  const { variables, updateVariables } = useContext(AIContext);

  const updateVariable = (type: "name" | "value", value: string) => {
    let currentVariable = variables.find(
      (data: VariableType) => data.id === props.id
    ) as VariableType;
    let updatedVariable: VariableType;
    let updatedVariables = [...variables];
    switch (type) {
      case "name":
        updatedVariable = { ...currentVariable, name: value };
        updatedVariables.splice(
          variables.indexOf(currentVariable),
          1,
          updatedVariable
        );
        updateVariables(updatedVariables);
        break;
      case "value":
        updatedVariable = { ...currentVariable, value: value };
        updatedVariables.splice(
          variables.indexOf(currentVariable),
          1,
          updatedVariable
        );
        updateVariables(updatedVariables);
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "8px",
        backgroundColor: "#141414",
        // border: "1px solid #545454",
        borderRadius: "8px",

        // border: "1px solid red",
        boxSizing: "border-box",
      }}
    >
      Nom :
      <Input
        value={props.name}
        onChange={(e) => updateVariable("name", e.target.value)}
      />
      Valeur:
      <Input.TextArea
        value={props.value}
        onChange={(e) => updateVariable("value", e.target.value)}
      />
      <span style={{ color: "rgba(255,255,255,0.3" }}>id: {props.id}</span>
    </div>
  );
}
