import { FullscreenOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import { useContext, useState } from "react";
import { markAsUntransferable } from "worker_threads";
import AIContext from "../../../../context/AIContext";
import { BlockType } from "../../../../types/AITypes";
import Block from "./components/Block";

export default function Blocks() {
  const { blocks, updateBlocks } = useContext(AIContext);

  const addBlock = () => {
    let newBlock: BlockType = {
      id: Math.random().toString(16).slice(2),
      title: "Nouveau Block",
      input: "",
      prompt: [{ role: "user", content: "", maxNewTokens: null }],
      outputOperation: "add",
      outputVariable: "continue",
    };
    let updatedBlocks = [...blocks];
    updatedBlocks.push(newBlock);
    updateBlocks(updatedBlocks);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        padding: "8px",
        // backgroundColor: "#141414",
        border: "1px solid #545454",
        boxSizing: "border-box",
        gap: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p>Blocks</p>
        <Button onClick={addBlock}>Add block</Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          gap: "8px",
        }}
      >
        {blocks.map((block: BlockType) => {
          return (
            <Block
              id={block.id}
              title={block.title}
              input={block.input}
              prompt={block.prompt}
              outputOperation={block.outputOperation}
              outputVariable={block.outputVariable}
            />
          );
        })}
      </div>
    </div>
  );
}
