import { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import { Radio, Select, Space } from "antd";
import { convertDateIntoTimestamp } from "../../utils/sort";
import colors from "../../theme/thiana";
import "chart.js/auto";
import { MONTHS } from "../reports/BarGraph";
import { User } from "@thiana/api-thiana-client";

Chart.register(CategoryScale, LinearScale, BarElement, Title);

interface Props {
  users: User[];
}

type DataUser = {
  type: string;
  number: number;
};

const today = new Date();

export default function UserBarGraph(props: Props) {
  const [currentYear, setCurrentYear] = useState<string>(
    today.getFullYear().toString()
  );
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: "Nombre de users",
        data: [],
        backgroundColor: ["rgba(75, 192, 192, 1)"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  const [duration, setDuration] = useState<"day" | "month" | "total">("day");

  const ChangeGraphDuration = () => {
    const handleSizeChange = (e: any) => {
      setDuration(e.target.value);
    };
    return (
      <>
        <Radio.Group value={duration} onChange={handleSizeChange}>
          <Radio.Button value="day">Nouveaux par jour</Radio.Button>
          <Radio.Button value="month">Nouveaux par mois</Radio.Button>
          <Radio.Button value="total">Total par mois</Radio.Button>
        </Radio.Group>
        <br />
        <br />
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        ></Space>
      </>
    );
  };

  useEffect(() => {
    if (duration === "day") {
      loadByDay();
    } else if (duration === "month") {
      loadByMonth();
    } else if (duration === "total") {
      loadByTotalMonth();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users, duration, currentYear]);

  const loadByTotalMonth = () => {
    const countByMonth: { [key: string]: number } = {};

    const sortedUsers = props.users.sort((a, b) => {
      return (
        convertDateIntoTimestamp(a.created_at) -
        convertDateIntoTimestamp(b.created_at) // jj/mm/aaaa hh:mm:ss
      );
    });

    let totalUsers = 0;

    sortedUsers.forEach((user: User) => {
      const parts = user.created_at.split("/");
      //convertir le mois en INT (ex: fevrier-> 02 = 2)
      const nbMonth = parseInt(parts[1], 10);
      const nbYear = parts[2].slice(0, 4);

      if (nbYear === currentYear) {
        const monthName = MONTHS[nbMonth - 1];
        totalUsers++;
        // les users sont tier en amont, donc on ne rreremplis jamais un mois lorsque l'on passe au suivant
        countByMonth[monthName] = totalUsers;
      }
    });

    const lineChartData: DataUser[] = MONTHS.map((month) => ({
      type: month,
      number: countByMonth[month] || 0,
    }));

    setChartData({
      labels: lineChartData.map((item) => item.type),
      datasets: [
        {
          label: "Total users",
          data: lineChartData.map((item) => item.number),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });
  };

  const loadByMonth = () => {
    const countByMonth: { [key: string]: number } = {};

    props.users.forEach((user: User) => {
      const parts = user.created_at.split("/");
      const nbMonth = parseInt(parts[1], 10);
      const nbYear = parts[2].slice(0, 4);

      if (nbYear === currentYear) {
        const monthName = MONTHS[nbMonth - 1];
        if (!countByMonth[monthName]) countByMonth[monthName] = 0;
        countByMonth[monthName]++;
      }
    });

    const barChartData: DataUser[] = MONTHS.map((month) => ({
      type: month,
      number: countByMonth[month] || 0,
    }));

    setChartData({
      labels: barChartData.map((item) => item.type),
      datasets: [
        {
          label: "Nombre de users",
          data: barChartData.map((item) => item.number),
          backgroundColor: ["rgba(75, 192, 192, 1)"],
        },
      ],
    });
  };

  const loadByDay = () => {
    const countByDate: { [key: string]: number } = {};

    props.users.forEach((user: User) => {
      const date = user.created_at.slice(0, 5);
      if (countByDate[date]) countByDate[date]++;
      else countByDate[date] = 1;
    });

    const data: DataUser[] = Object.entries(countByDate).map(
      ([key, value]) => ({
        type: key,
        number: value,
      })
    );

    setChartData({
      labels: data.map((item) => item.type),
      datasets: [
        {
          label: "Nombre de users",
          data: data.map((item) => item.number),
          backgroundColor: ["rgba(75, 192, 192, 1)"],
        },
      ],
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginBottom: 20,
          gap: 15,
        }}
      >
        <Select
          onChange={(value: string) => setCurrentYear(value)}
          value={currentYear}
          options={[
            { value: "2023", label: 2023 },
            { value: "2024", label: 2024 },
          ]}
        />
        {ChangeGraphDuration()}
      </div>

      <div
        style={{
          marginTop: 5,
          marginBottom: 10,
          color: colors.palette.grey[600],
          fontSize: 10,
          fontStyle: "italic",
          textAlign: "right",
        }}
      >
        données calculées à partir du created_at
      </div>
      {duration === "total" ? (
        <Line
          data={chartData}
          options={{
            plugins: {
              title: {
                display: true,
              },
            },
          }}
        />
      ) : (
        <Bar
          data={chartData}
          style={{ maxHeight: 200 }}
          options={{
            plugins: {
              title: {
                display: true,
              },
            },
          }}
        />
      )}
    </>
  );
}
