import { Drawer } from "antd";
import { Subscription } from "@thiana/api-thiana-client";

export default function SubscriptionDrawer(props: {
  visible: boolean;
  onClose: () => void;
  subscription: Subscription | null;
}) {
  const { visible, onClose, subscription } = props;

  return (
    <Drawer
      title="Subscription ID"
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      {subscription && (
        <div>
          <p>
            <b>ID:</b>
            <br />
            {subscription.id}
          </p>
          <p>
            <b>Customer ID:</b>
            <br />
            {subscription.customer_id}
          </p>
          <p>
            <b>External ID:</b>
            <br />
            {subscription.external_id}
          </p>
          <p>
            <b>Scope ID:</b>
            <br />
            {subscription.scope_id}
          </p>
        </div>
      )}
    </Drawer>
  );
}
