export default function useLogout() {
  const logout = async (
    env: "development" | "preview" | "production" | "audit" | "all"
  ) => {
    if (env === "all") {
      localStorage.clear();
      window.location.replace("/");
    } else {
      localStorage.removeItem("accessJWT-" + env);
      localStorage.removeItem("refreshJWT-" + env);
      window.location.replace("/");
    }
  };

  return { logout };
}
