import { Select, Table, Pagination, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { Subscription, SubscriptionOutput } from "@thiana/api-thiana-client";
import { ThianaPagination } from "../../types/Pagination";
import React from "react";

export interface SubscriptionDataSource extends Subscription {
  key: string;
}

interface Props {
  subscriptionsDataSource: SubscriptionDataSource[];
  setSubscriptionsDataSource: React.Dispatch<
    React.SetStateAction<SubscriptionDataSource[]>
  >;
  subscriptionsPagination: ThianaPagination;
  setSubscriptionsPagination: React.Dispatch<
    React.SetStateAction<ThianaPagination>
  >;
  fetchSubscriptions: () => Promise<void>;
  fetchSubscriptionsByCustomerID: () => Promise<void>;
  onChangePaymentLevel: (
    paymentLevel: number,
    subscription: Subscription
  ) => Promise<void>;
  onChangeStatus: (status: string, subscription: Subscription) => Promise<void>;
  showDrawer: (subscription: Subscription) => void;
  // fetchReportCount: (customerId: string) => Promise<number>;
  // fetchUserName: (customerId: string) => Promise<string>;
}

export default function SubscriptionList(props: Props) {
  const {
    subscriptionsDataSource,
    setSubscriptionsPagination,
    subscriptionsPagination,
    onChangePaymentLevel,
    onChangeStatus,
    showDrawer,
    // fetchReportCount,
    // fetchUserName,
  } = props;

  const columns: ColumnsType<SubscriptionDataSource> = [
    {
      title: <code style={{ fontSize: 10 }}>customer_id</code>,
      dataIndex: "customer_id",
      key: "customer_id",
      render: (customer_id: string) => (
        <div style={{ fontSize: 10 }}>{customer_id}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>checked_until</code>,
      key: "checked_until",
      dataIndex: "checked_until",
      render: (checked_until: string) => (
        <div style={{ fontSize: 10 }}>{checked_until}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>created_at</code>,
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: string) => (
        <div style={{ fontSize: 10 }}>{created_at}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>payment_level</code>,
      key: "payment_level",
      render: (subscription: Subscription) => (
        <Select
          onChange={(value) =>
            onChangePaymentLevel(Number(value), subscription)
          }
          style={{ width: 150 }}
          size="small"
          value={subscription.payment_level}
          options={[
            { value: "1", label: "Lecture seule" },
            { value: "3", label: "Offre essentielle" },
            { value: "2", label: "Offre Intégrale" },
          ]}
        />
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>status</code>,
      key: "status",
      render: (subscription: Subscription) => (
        <Select
          style={{ width: 150 }}
          onChange={(value) => onChangeStatus(value, subscription)}
          size="small"
          value={subscription.status}
          options={[
            { value: "canceled", label: "Annulé" },
            { value: "trialing", label: "Période d'essai" },
            { value: "active", label: "Actif" },
          ]}
        />
      ),
    },

    {
      title: <code style={{ fontSize: 10 }}>trial_until</code>,
      dataIndex: "trial_until",
      key: "trial_until",
      render: (trial_until: string) => (
        <div style={{ fontSize: 10 }}>{trial_until}</div>
      ),
    },
    {
      title: <code style={{ fontSize: 10 }}>updated_at</code>,
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at: string) => (
        <div style={{ fontSize: 10 }}>{updated_at}</div>
      ),
    },
    // {
    //   title: <code style={{ fontSize: 10 }}>Nombre de reports</code>,
    //   key: "reportCount",
    //   dataIndex: "reportCount",
    //   render: (reportCount: number) => (
    //     <div style={{ fontSize: 10 }}>{reportCount}</div>
    //   ),
    // },
    {
      title: <code style={{ fontSize: 10 }}>Actions</code>,
      key: "actions",
      render: (subscription: Subscription) => (
        <Button
          type="link"
          onClick={() => showDrawer(subscription)}
          style={{ fontSize: 10 }}
        >
          Voir
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{
        border: "1px solid rgba(128, 128, 128, 0.5)",
        borderRadius: "5px",
      }}
    >
      <Table
        columns={columns}
        dataSource={subscriptionsDataSource}
        scroll={{ x: 1300 }}
        pagination={false}
      />
      <Pagination
        style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
        defaultCurrent={subscriptionsPagination.currentPage}
        current={subscriptionsPagination.currentPage}
        total={subscriptionsPagination.totalItems}
        onChange={(newPage) =>
          setSubscriptionsPagination({
            ...subscriptionsPagination,
            currentPage: newPage,
          })
        }
        showSizeChanger={false}
        showTotal={(total) => (
          <span style={{ fontSize: 12, color: "grey" }}>
            {total} subscriptions au total
          </span>
        )}
      />
    </div>
  );
}
