import { Breadcrumb } from "antd";

export default function Workspaces() {
  return (
    <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Global",
            },
            {
              title: "Workspaces",
            },
          ]}
        />
      </div>
    </div>
  );
}
