import {
  DownloadOutlined,
  RocketOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import useAILab from "../../../../../hooks/useAILab";
import useJSONForAI from "../../../../../hooks/useJSONForAI";

export default function Commands() {
  const { launchPipeline, abortPipeline } = useAILab();
  const { downloadJSONState } = useJSONForAI();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "4px",
      }}
    >
      <Button
        block
        size="large"
        type="default"
        onClick={downloadJSONState}
        icon={<DownloadOutlined />}
      >
        Download JSON data
      </Button>
      <Button
        block
        size="large"
        type="primary"
        onClick={launchPipeline}
        icon={<RocketOutlined />}
      >
        Launch pipeline
      </Button>
      <Button
        block
        danger
        size="large"
        type="primary"
        onClick={abortPipeline}
        icon={<StopOutlined />}
      >
        Abort
      </Button>
    </div>
  );
}
