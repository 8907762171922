import { useContext, useEffect, useState } from "react";
import MainMenu from "./Menu";
import Context from "../../context/Context";
import { getFormattedEnvironment } from "../../utils/env";
import FloatButtons from "./FloatButtons";
const pageLS = localStorage.getItem("page");

export default function Layout(props: any) {
  const { env } = useContext(Context);
  const [page, setPage] = useState<string>(pageLS || "/users");

  // useEffect qui change le label de l'onglet du navigateur
  useEffect(() => {
    document.title = "Thiana Admin  - " + getFormattedEnvironment(env);
  }, [env]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
      }}
    >
      <FloatButtons />
      <div
        style={{
          flex: 1,
          backgroundColor: "#141414",
          borderRight: "1px solid #262626",
          padding: 10,
        }}
      >
        <MainMenu page={page} setPage={setPage} />
      </div>
      <div
        style={{
          flex: 8,
          backgroundColor: "#1c1c1c",
          padding: 5,
          overflow: "auto",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
