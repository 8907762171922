import Layout from "./components/common/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppContext from "./context/Context";
import { useState } from "react";
import Professionals from "./components/professionals/Professionals";
import { ConfigProvider, theme } from "antd";
import Reports from "./components/reports/Reports";
import Protected from "./components/common/Protected";
import Logout from "./components/logout/Logout";
import "./App.css";
import Users from "./components/users/Users";
import { HelmetProvider } from "react-helmet-async";
import { ActiveUserFilter } from "./types/Users";
import ViewController from "./components/common/ViewController";
import LLMTesting from "./components/ai-lab/LLMTesting/LLMTesting";
import TestSynapse from "./components/test-synapse/TestSynapse";
import ASRTesting from "./components/ai-lab/ASRTesting/ASRTesting";
import Workspaces from "./components/workspaces/workspaces";
import Customers from "./components/customers/Customers";
import Subscriptions from "./components/subscriptions/Subscriptions";
import Patients from "./components/patients/Patients";
import useApiThianaClient, {
  ApiThianaClient,
} from "./api/api-thiana-client/useApiThianaClient";

const envLS = localStorage.getItem("env") || "development";
let activeUserFiltersLSString = localStorage.getItem("activeUserFilters");
const activeUserFiltersLS = activeUserFiltersLSString
  ? JSON.parse(activeUserFiltersLSString)
  : [];

function App() {
  const { darkAlgorithm } = theme;
  const [env, setEnv] = useState<
    "development" | "preview" | "production" | "audit"
  >(envLS as "development" | "preview" | "production" | "audit");
  const [activeUserFilters, setActiveUserFilters] =
    useState<ActiveUserFilter[]>(activeUserFiltersLS);

  const apiThianaClient: ApiThianaClient = useApiThianaClient({ env });

  // Set initial values for app context
  const initialsValues = {
    apiThianaClient,
    env,
    updateEnv: setEnv,
    activeUserFilters,
    updateActiveUserFilters: setActiveUserFilters,
  };

  return (
    <HelmetProvider>
      <link
        id="favicon"
        rel="icon"
        href={
          env === "development"
            ? "/favicon-green.ico"
            : env === "preview"
            ? "/favicon-orange.ico"
            : "/favicon-red.ico"
        }
      />
      <ConfigProvider
        theme={{
          algorithm: darkAlgorithm,
        }}
      >
        <AppContext.Provider value={initialsValues}>
          <BrowserRouter>
            <Protected>
              <Routes>
                <Route
                  path="/:environment?"
                  element={
                    <Layout>
                      <ViewController />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/users"
                  element={
                    <Layout>
                      <Users />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/professionals"
                  element={
                    <Layout>
                      <Professionals />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/workspaces"
                  element={
                    <Layout>
                      <Workspaces />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/customers"
                  element={
                    <Layout>
                      <Customers />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/subscriptions"
                  element={
                    <Layout>
                      <Subscriptions />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/patients"
                  element={
                    <Layout>
                      <Patients />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/documents"
                  element={
                    <Layout>
                      <div>documents</div>
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/reports/:id?"
                  element={
                    <Layout>
                      <Reports />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/models"
                  element={
                    <Layout>
                      <div>models</div>
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/llm-testing"
                  element={
                    <Layout>
                      <LLMTesting />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/asr-testing"
                  element={
                    <Layout>
                      <ASRTesting />
                    </Layout>
                  }
                />
                <Route
                  path="/:environment/test-synapse"
                  element={
                    <Layout>
                      <TestSynapse />
                    </Layout>
                  }
                />
                <Route path="/logout" element={<Logout />} />
              </Routes>
            </Protected>
          </BrowserRouter>
        </AppContext.Provider>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
