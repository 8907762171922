import { notification } from "antd";
import { errorWithCapitalization } from "../utils/errors";
import { BackendError } from "../types/BackendErrors";

/**
 * Ce middleware permet de récupérer (et stocker dans le localStorage) les nouveaux tokens si la requête revient en success
 * Si la reqûete revient en erreur 401, on déconnecte le user via la route /logout
 * @param response réponse de la requête
 * @returns
 */
const middleware = async (response: Response) => {
  // Success
  if (response.status >= 200 && response.status < 300) {
    const env = localStorage.getItem("env");
    let accessToken = response.headers.get("X-Access-Token");
    let refreshToken = response.headers.get("X-Refresh-Token");
    if (accessToken) localStorage.setItem("accessJWT-" + env, accessToken);
    if (refreshToken) localStorage.setItem("refreshJWT-" + env, refreshToken);
  }
  // Erreur
  else {
    // Si erreur 401 Unauthorized => logout
    if (response.status === 401) window.location.replace("/logout");
    // Sinon on affiche juste l'erreur renvoyé par le back
    let loginResponse = await response.json();
    loginResponse.errors?.forEach((error: BackendError) => {
      notification.error({
        message: "Erreur",
        description: errorWithCapitalization(error.message),
      });
    });
  }
  return response;
};

// Récupère l'url du back sur lequel on veut effectuer la requête
export const getUrl = (
  paramEnv?: "development" | "preview" | "production" | "audit"
) => {
  let env: "development" | "preview" | "production" | "audit" | string | null;
  if (paramEnv) env = paramEnv;
  else env = localStorage.getItem("env");
  switch (env) {
    case "development":
      return process.env.REACT_APP_URL_BACKAPP_DEVELOPMENT;
    case "preview":
      return process.env.REACT_APP_URL_BACKAPP_PREVIEW;
    case "production":
      return process.env.REACT_APP_URL_BACKAPP_PRODUCTION;
    case "audit":
      return process.env.REACT_APP_URL_BACKAPP_AUDIT;
    default:
      return process.env.REACT_APP_URL_BACKAPP_DEVELOPMENT;
  }
};
const get = async (route: string) => {
  const url = getUrl();
  const env = localStorage.getItem("env");
  const token = localStorage.getItem("accessJWT-" + env);
  return await fetch(url + route, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const patch = async (route: string, data: object) => {
  const url = getUrl();
  const env = localStorage.getItem("env");
  const accessToken = localStorage.getItem("accessJWT-" + env);
  return await middleware(
    await fetch(url + route, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
  );
};
/* Back App Status */
export const getBackAppStatus = async () => {
  return await get("/health");
};
export const getBackAppStatusByEnv = async (
  env: "development" | "preview" | "production" | "audit"
) => {
  const url = getUrl(env);
  return await fetch(url + "/health");
};
export const patchProfessionalById = async (id: string, data: object) => {
  return await patch("/professionals/" + id, data);
};
