import { RequestContext } from "@thiana/api-thiana-client";
import { getUrl } from "../api";

export const preMiddleware = async (context: RequestContext) => {
  const env = localStorage.getItem("env");
  const accessJWT = localStorage.getItem("accessJWT-" + env);
  let url = getUrl();
  if (url) context.url = url;
  if (accessJWT) {
    context.init.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessJWT}`,
    };
    return context;
  } else {
    context.init.headers = {
      "Content-Type": "application/json",
    };
    return context;
  }
};
