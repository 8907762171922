import {
  ColorsThiana,
  BoxShadowsThiana,
  FontSizeThiana,
  FontWeightThiana,
  Palette,
} from "../types/Theme";
const palette: Palette = {
  primary: {
    50: "#EAF8FF",
    100: "#BDE9FF",
    200: "#91C9E6",
    300: "#7AC1E6",
    400: "#63B9E6",
    500: "#4DB2E6",
    600: "#3C8AB3",
    700: "#2B6380",
    800: "#1A3B4D",
    900: "#112733",
  },
  secondary: {
    50: "#F7F6FF",
    100: "#EEECFF",
    200: "#DDD8FF",
    300: "#CBC0FE",
    400: "#AD95FB",
    500: "#9162FA",
    600: "#7F3BF3",
    700: "#6D2CDA",
    800: "#5622B6",
    900: "#4B1E97",
  },
  third: {
    50: "#E1FFDE",
    100: "#9CFF92",
    200: "#8CE683",
    300: "#7DCC74",
    400: "#6DB366",
    500: "#5CB253",
    600: "#4F9947",
    700: "#42803B",
    800: "#35662F",
    900: "#284D23",
  },
  success: {
    50: "#E8F6ED",
    100: "#D1EEDB",
    200: "#BAE5C8",
    300: "#8CD4A4",
    400: "#5EC37F",
    500: "#52A86E",
    600: "#307F4A",
    700: "#21713B",
    800: "#1B4729",
    900: "#102A19",
  },
  error: {
    50: "#FFD5CC",
    100: "#FFAA99",
    200: "#FF9580",
    300: "#FF7F66",
    400: "#FF6A4D",
    500: "#EF4444",
    600: "#D63C3C",
    700: "#BD3535",
    800: "#A32E2E",
    900: "#8A2727",
  },
  warning: {
    50: "#FFF0D8",
    100: "#FFE9C5",
    200: "#FFDB9E",
    300: "#FFC564",
    400: "#FFC564",
    500: "#F4A118",
    600: "#E49614",
    700: "#B6760E",
    800: "#6B470B",
    900: "#3C2604",
  },
  grey: {
    50: "#FDFFFF",
    100: "#F8F9FA",
    200: "#E9ECEF",
    300: "#DEE2E6",
    400: "#CED4DA",
    500: "#ADB5BD",
    600: "#6C757D",
    700: "#495057",
    800: "#343A40",
    900: "#212529",
  },
  blue: {
    50: "#EDF6FF",
    100: "#CCE3FA",
    200: "#99C7F5",
    300: "#66AAF0",
    400: "#338EEB",
    500: "#0072E6",
    600: "#005BB8",
    700: "#00448A",
    800: "#002E5C",
    900: "#00172E",
  },
  orange: {
    50: "#FFF8FA",
    100: "#FFECDC",
    200: "#FCD9BD",
    300: "#FDBA8C",
    400: "#FF8A4C",
    500: "#FF5A1F",
    600: "#D03801",
    700: "#B43403",
    800: "#8A2C0D",
    900: "#771D1D",
  },
  pink: {
    50: "#FCF1F7",
    100: "#FBE7F2",
    200: "#F9D0E7",
    300: "#F7B3D8",
    400: "#F07DB7",
    500: "#E64593",
    600: "#D51E68",
    700: "#BE115C",
    800: "#98144A",
    900: "#74193C",
  },
  teal: {
    50: "#E5FFFA",
    100: "#D0F6EF",
    200: "#A1EDDE",
    300: "#71E5CE",
    400: "#42DCBD",
    500: "#13D3AD",
    600: "#0FA98A",
    700: "#0B7F68",
    800: "#085445",
    900: "#042A23",
  },
  bluegrey: {
    50: "#D9E2EC",
    100: "#BCCCDC",
    200: "#9FB3C8",
    300: "#9FB3C8",
    400: "#829AB1",
    500: "#627D98",
    600: "#486581",
    700: "#334E68",
    800: "#243B53",
    900: "#102A43",
  },
  navy: {
    50: "#ECEFF1",
    100: "#C6CED6",
    200: "#B3BDC9",
    300: "#8D9CAD",
    400: "#677B92",
    500: "#415A77",
    600: "#34485F",
    700: "#273647",
    800: "#1A2430",
    900: "#131B24",
  },
  danger: {
    50: "#FCEAEA",
    100: "#F8D5D5",
    200: "#F1ABAB",
    300: "#EB8282",
    400: "#E45858",
    500: "#E73F3F",
    600: "#A11A1A",
    700: "#920E0E",
    800: "#450B0B",
    900: "#170404",
  },
};
export const colors: ColorsThiana = {
  palette: palette,
  documentsColors: {
    "0001": palette.primary[500],
    "1100": palette.primary[500],
    "1200": palette.danger[500],
    "1201": palette.success[500],
    "1202": palette.blue[500],
    "1300": palette.warning[700],
    "1301": palette.success[700],
    "1302": palette.blue[800],
    "1400": "black",
  },
};

export const borderRadiusThiana: number = 10;
export const paddingThiana: number = 10;
export const borderThiana: any = {
  default: "1px solid var(--border-default, #E6E6E6)",
};

export const fontWeightThiana: FontWeightThiana = {
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const fontSizeThiana: FontSizeThiana = {
  macro: 10,
  text_xs: 12,
  text_sm: 14,
  text_md: 16,
  text_lg: 18,
  text_xl: 20,
  display_xs: 24,
  display_sm: 32,
  display_md: 36,
  display_lg: 48,
  display_xl: 60,
  display_xxl: 72,
};

export const boxShadowThiana: BoxShadowsThiana = {
  small:
    "0px 1px 1px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px 0px rgba(24, 24, 28, 0.04)",
  medium:
    "0px 2px 4px -2px rgba(24, 24, 28, 0.06), 0px 4px 8px -2px rgba(24, 24, 28, 0.10)",
  large:
    "0px 2px 6px 0px rgba(24, 24, 28, 0.06), 0px 32px 41px -23px rgba(24, 24, 28, 0.07)",
  extralarge:
    "0px 48px 64px -36px rgba(24, 24, 28, 0.13), 0px 24px 48px -8px rgba(24, 24, 28, 0.11)",
};
export default colors;
