import {
  CheckOutlined,
  PlusOutlined,
  RestOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Input, Modal, Spin } from "antd";
import { useState, useEffect, useContext } from "react";
import { ActiveUserFilter, User, UsersResponse } from "../../types/Users";
import Context from "../../context/Context";
import { getFormattedGlobal, showS } from "../../utils/formatting";
import useAvatar from "../../hooks/useAvatar";
import colors from "../../theme/thiana";

interface Props {
  openFilterModal: boolean;
  setOpenFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function FiltersModal(props: Props) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [usersResult, setUsersResult] = useState<ActiveUserFilter[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { activeUserFilters, updateActiveUserFilters, apiThianaClient } =
    useContext(Context);
  const { getFileAvatarByProfessionalID } = useAvatar();

  useEffect(() => {
    if (searchValue.length > 2) {
      setIsFetching(true);
      (async () => {
        let usersResponse: UsersResponse;
        let response = await apiThianaClient.apiUsers.userGetAllRaw({
          mode: "OR",
          like:
            "last_name:" +
            searchValue +
            "|first_name:" +
            searchValue +
            "|email:" +
            searchValue,
        });
        if (response.raw.status === 200) {
          usersResponse = await response.raw.json();
          let promises = usersResponse.data.map(async (user: User) => {
            let { responseStatus, fileAvatar } =
              await getFileAvatarByProfessionalID(user.professional_id);
            if (responseStatus === 200) return { ...user, fileAvatar };
            else return { ...user };
          });
          let tmp: any = await Promise.all(promises);
          setIsFetching(false);
          setUsersResult(tmp);
        } else setIsFetching(false);
      })();
    } else setUsersResult([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const addUserToFilters = (activeUserFilter: ActiveUserFilter) => {
    // On check si le user est déjà dans le filtre ou non. Si oui, on le rajoute pas une seconde fois
    if (
      activeUserFilters.findIndex(
        (activeUserFilterFI: ActiveUserFilter) =>
          activeUserFilterFI.id === activeUserFilter.id
      ) === -1
    )
      updateActiveUserFilters([...activeUserFilters, activeUserFilter]);
  };

  const removeUserFromFilters = (activeUserFilter: ActiveUserFilter) => {
    updateActiveUserFilters([
      ...activeUserFilters.filter(
        (userFilter: User) => userFilter.id !== activeUserFilter.id
      ),
    ]);
  };

  useEffect(() => {
    localStorage.setItem(
      "activeUserFilters",
      JSON.stringify(activeUserFilters)
    );
  }, [activeUserFilters]);

  return (
    <Modal
      open={props.openFilterModal}
      cancelText={"Annuler"}
      okText={"Filtrer"}
      onCancel={() => {
        props.setOpenFilterModal(false);
        setSearchValue("");
        setUsersResult([]);
      }}
      footer={false}
      width={"60%"}
    >
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {activeUserFilters.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              marginBottom: 10,
            }}
          >
            <div
              style={{ color: colors.palette.grey[600], fontWeight: "bold" }}
            >
              {activeUserFilters.length} filtre{showS(activeUserFilters.length)}{" "}
              {getFormattedGlobal(activeUserFilters.length)} actif
              {showS(activeUserFilters.length)}
            </div>
            <Button
              size="small"
              shape="circle"
              type="text"
              onClick={() => updateActiveUserFilters([])}
              icon={<RestOutlined />}
            ></Button>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            marginBottom: 20,
          }}
        >
          {activeUserFilters.map((activeUserFilter: ActiveUserFilter) => {
            return (
              <div key={"filters-" + activeUserFilter.id}>
                <div
                  style={{
                    cursor: "pointer",
                    borderRadius: 30,
                    backgroundColor: colors.palette.grey[800],
                    padding: "5px 15px 5px 5px",
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                  }}
                  onClick={() => removeUserFromFilters(activeUserFilter)}
                >
                  <Avatar
                    size={"small"}
                    src={activeUserFilter.fileAvatar}
                    icon={
                      !activeUserFilter.fileAvatar ? <UserOutlined /> : null
                    }
                  />
                  {activeUserFilter.first_name} {activeUserFilter.last_name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Rechercher un utilisateur par prénom OU nom OU email..."
          addonAfter={<SearchOutlined />}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            justifyContent: "center",
            overflowY: "auto",
          }}
        >
          {isFetching ? (
            <Spin style={{ margin: 50 }} />
          ) : (
            usersResult?.map((userResult: ActiveUserFilter) => {
              return (
                <div
                  key={"result" + userResult.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      gap: 10,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={userResult.fileAvatar}
                      icon={!userResult.fileAvatar ? <UserOutlined /> : null}
                    />
                    {userResult.first_name} {userResult.last_name}
                  </div>

                  <div style={{ flex: 1 }}> {userResult.email}</div>

                  <div style={{ flex: 1 }}>{userResult.birth_date}</div>
                  {activeUserFilters.findIndex(
                    (activeUserFilterFI: ActiveUserFilter) =>
                      activeUserFilterFI.id === userResult.id
                  ) === -1 ? (
                    <Button
                      size="small"
                      type="text"
                      shape="circle"
                      onClick={() => addUserToFilters(userResult)}
                      icon={<PlusOutlined />}
                    ></Button>
                  ) : (
                    <Button
                      disabled
                      size="small"
                      type="text"
                      shape="circle"
                      onClick={() => addUserToFilters(userResult)}
                    >
                      <CheckOutlined
                        style={{ color: colors.palette.success[600] }}
                      />
                    </Button>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </Modal>
  );
}
