import {
  Avatar,
  Button,
  Drawer,
  Input,
  Modal,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useEffect, useState } from "react";
import colors from "../../../theme/thiana";
import { ProfessionalDataSource } from "../Professionals";
import { patchProfessionalById } from "../../../api/api";
import { PlusOutlined } from "@ant-design/icons";
// import ImgCrop from "antd-img-crop";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  professionalsDataSource: ProfessionalDataSource[];
  setProfessionalsDataSource: React.Dispatch<
    React.SetStateAction<ProfessionalDataSource[]>
  >;
  selectedProfessional: ProfessionalDataSource | null;
  setSelectedProfessional: React.Dispatch<
    React.SetStateAction<ProfessionalDataSource | null>
  >;
};

// type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
// type FileType = Parameters<UploadProps["beforeUpload"]>[0];

export default function DrawerListProfessional(props: Props) {
  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formValues, setFormValues] = useState({
    speciality: props.selectedProfessional?.speciality,
    status: props.selectedProfessional?.status,
    lastName: props.selectedProfessional?.user?.last_name,
    firstName: props.selectedProfessional?.user?.first_name,
    phone: props.selectedProfessional?.phone,
    avatar: props.selectedProfessional?.avatar,
  });
  const [certifUrl, setCertifUrl] = useState<string | null>(null);
  //--------------------------------------------------------------
  // const [fileList, setFileList] = useState<UploadFile[]>([
  //   {
  //     uid: "-1",
  //     name: "image.png",
  //     status: "done",
  //     url: formValues.avatar,
  //   },
  // ]);

  // const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };

  // const onPreview = async (file: UploadFile) => {
  //   let src = file.url as string;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj as FileType);
  //       reader.onload = () => resolve(reader.result as string);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(image.outerHTML);
  // };
  //--------------------------------------------------------------
  const onClose = () => {
    console.log("Selected User on close:", props.selectedProfessional);
    props.setOpen(false);
    props.setSelectedProfessional(null);
    setHasChanges(false);
    setFormValues({
      firstName: "",
      lastName: "",
      phone: "",
      status: "",
      speciality: "",
      avatar: "",
    });
  };

  const openConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const handleConfirmModalCancel = () => {
    setConfirmModalVisible(false);
  };

  // const handleCertif = async () => {
  //   let idCertif = "cc634e64-8a29-4566-99b3-2d6c4f8da7b3";
  //   if (idCertif) {
  //     let responseCertif = await getFileFromS3(idCertif);
  //     let certifData = await responseCertif.json();
  //     let url = certifData;
  //     setCertifUrl(url);
  //     console.log("response certif " + responseCertif);
  //     console.log("certif url " + certifUrl);
  //   }
  // };

  const handleConfirmModalOk = async () => {
    let id = props.selectedProfessional?.id;
    if (id) {
      let response = await patchProfessionalById(id, {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        speciality: formValues.speciality,
        status: formValues.status,
        phone: formValues.phone,
        avatar: formValues.avatar,
      });

      if (response.status === 200) {
        setHasChanges(false);
        setConfirmModalVisible(false);
      }
    }
    setHasChanges(false);
    setConfirmModalVisible(false);
  };

  const handleInputChange = async (fieldName: string, value: string) => {
    const updatedFormValues = {
      ...formValues,
      [fieldName]: value,
    };
    setFormValues(updatedFormValues);
    setHasChanges(true);
  };

  // const checkEmail = () => {
  //   if (isEmailValid) return null;
  //   else
  //     return (
  //       <div style={{ color: colors.palette.danger[500], fontSize: 10 }}>
  //         Format invalide
  //       </div>
  //     );
  // };

  useEffect(() => {
    setFormValues({
      speciality: props.selectedProfessional?.speciality || "",
      status: props.selectedProfessional?.status || "",
      lastName: props.selectedProfessional?.user?.last_name || "",
      firstName: props.selectedProfessional?.user?.first_name || "",
      phone: props.selectedProfessional?.phone || "",
      avatar: props.selectedProfessional?.avatar || "",
    });
    // handleCertif();
  }, [props.selectedProfessional]);

  return (
    <div>
      <Drawer
        style={{ background: "#1c1c1c" }}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: colors.palette.grey[100] }}>
              User informations
            </span>
            <Button type="primary" danger onClick={onClose}>
              Supprimer
            </Button>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={props.open}
        width={"50%"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {/* Identité */}
            <div style={{ marginBottom: 16 }}>
              {/* <h3>Identité</h3> */}
              <div style={{ display: "flex", gap: 16, marginBottom: 15 }}>
                {/* <ImgCrop rotationSlider>
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
                <div style={{ width: "50%" }}>
                  <label>Prénom</label>
                  <Input
                    placeholder="Prénom"
                    value={formValues.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                  />
                </div> */}
                <Avatar
                  style={{ width: 40, height: 40 }}
                  src={formValues.avatar}
                  // src={props.professionalDataSource.fileAvatar}
                />
                <div style={{ width: 96 }}>
                  <label>Sexe</label>
                  <Select
                    showSearch
                    placeholder="Choisir"
                    // value={patient.sex}
                    style={{ width: "100%" }}
                    // onChange={handleChangeSex}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      { value: "M", label: "Homme" },
                      { value: "F", label: "Femme" },
                    ]}
                  />
                </div>
              </div>
              <div style={{ width: "50%", marginBottom: 15 }}>
                <label>Nom</label>
                <Input
                  placeholder="Nom"
                  value={formValues.lastName}
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                />
              </div>
              <div style={{ width: "50%", marginBottom: 15 }}>
                <label>Status</label>
                <Input
                  placeholder="Status"
                  value={props.selectedProfessional?.status}
                  onChange={(e) => handleInputChange("status", e.target.value)}
                />
              </div>
              <div style={{ width: "50%", marginBottom: 15 }}>
                <label>Spécialité</label>
                <Input
                  placeholder="Specialité"
                  value={formValues.speciality}
                  onChange={(e) =>
                    handleInputChange("speciality", e.target.value)
                  }
                />
              </div>
            </div>

            {/* Contact */}
            <div style={{ marginBottom: 16 }}>
              <h3>Contact</h3>
              <div style={{ display: "flex", gap: 16 }}>
                <div style={{ width: "50%" }}>
                  <label>Numéro de téléphone</label>
                  <Input
                    placeholder="Numéro de téléphone"
                    value={formValues.phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button
              style={{ background: "yellow", color: "black" }}
              onClick={openConfirmModal}
              disabled={!hasChanges}
            >
              Modifier
            </Button>
          </div>
        </div>
      </Drawer>
      <Modal
        title="Confirmer la modification"
        open={confirmModalVisible}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
      >
        <p>Êtes-vous sûr de vouloir modifier cet utilisateur ?</p>
      </Modal>
    </div>
  );
}
